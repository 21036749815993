"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SortFunctionsOnDetails = exports.SortFunctions = exports.ORDERED_SORTS = exports.SORTS = void 0;
exports.getColorIdentity = getColorIdentity;
exports.getColorCombination = getColorCombination;
exports.GetColorCategory = GetColorCategory;
exports.cardGetLabels = cardGetLabels;
exports.cardCanBeSorted = cardCanBeSorted;
exports.cardIsLabel = cardIsLabel;
exports.formatLabel = formatLabel;
exports.getLabels = getLabels;
exports.sortGroupsOrdered = sortGroupsOrdered;
exports.sortIntoGroups = sortIntoGroups;
exports.sortDeep = sortDeep;
exports.countGroup = countGroup;
exports.sortForDownload = sortForDownload;
const Card_1 = require("../../datatypes/Card");
const cardutil_1 = require("./cardutil");
const Util_1 = require("./Util");
const COLOR_MAP = {
    W: 'White',
    U: 'Blue',
    B: 'Black',
    R: 'Red',
    G: 'Green',
};
const GUILD_MAP = {
    WU: 'Azorius',
    UB: 'Dimir',
    BR: 'Rakdos',
    RG: 'Gruul',
    WG: 'Selesnya',
    WB: 'Orzhov',
    UR: 'Izzet',
    BG: 'Golgari',
    WR: 'Boros',
    UG: 'Simic',
};
const SHARD_AND_WEDGE_MAP = {
    WUG: 'Bant',
    WUB: 'Esper',
    UBR: 'Grixis',
    BRG: 'Jund',
    WRG: 'Naya',
    WBG: 'Abzan',
    WUR: 'Jeskai',
    UBG: 'Sultai',
    WBR: 'Mardu',
    URG: 'Temur',
};
const FOUR_COLOR_MAP = {
    UBRG: 'Non-White',
    WBRG: 'Non-Blue',
    WURG: 'Non-Black',
    WUBG: 'Non-Red',
    WUBR: 'Non-Green',
};
const CARD_TYPES = [
    'Creature',
    'Planeswalker',
    'Instant',
    'Sorcery',
    'Artifact',
    'Enchantment',
    'Conspiracy',
    'Contraption',
    'Phenomenon',
    'Plane',
    'Scheme',
    'Vanguard',
    'Land',
    'Battle',
];
const SINGLE_COLOR = ['White', 'Blue', 'Black', 'Red', 'Green'];
const GUILDS = [
    'Azorius',
    'Dimir',
    'Rakdos',
    'Gruul',
    'Selesnya',
    'Orzhov',
    'Izzet',
    'Golgari',
    'Boros',
    'Simic',
];
const SHARDS_AND_WEDGES = [
    'Bant',
    'Esper',
    'Grixis',
    'Jund',
    'Naya',
    'Mardu',
    'Temur',
    'Abzan',
    'Jeskai',
    'Sultai',
];
const FOUR_AND_FIVE_COLOR = ['Non-White', 'Non-Blue', 'Non-Black', 'Non-Red', 'Non-Green', 'Five Color'];
function defaultSort(x, y) {
    if (!/^\d+$/.test(x) || !/^\d+$/.test(y)) {
        return x < y ? -1 : 1;
    }
    return parseInt(x, 10) < parseInt(y, 10) ? -1 : 1;
}
function getColorIdentity(colors) {
    if (colors.length === 0) {
        return 'Colorless';
    }
    else if (colors.length === 1) {
        if (Object.keys(COLOR_MAP).includes(colors[0])) {
            return COLOR_MAP[colors[0]];
        }
        if (colors[0] === 'C') {
            return 'Colorless';
        }
        return 'None';
    }
    else {
        return 'Multicolored';
    }
}
function getColorCombination(colors) {
    if (colors.length < 2) {
        return getColorIdentity(colors);
    }
    const ordered = [...'WUBRG'].filter((c) => colors.includes(c)).join('');
    if (colors.length === 2) {
        return GUILD_MAP[ordered];
    }
    if (colors.length === 3) {
        return SHARD_AND_WEDGE_MAP[ordered];
    }
    if (colors.length === 4) {
        return FOUR_COLOR_MAP[ordered];
    }
    return 'Five Color';
}
function GetColorCategory(type, colors) {
    if (type.toLowerCase().includes('land')) {
        return 'Lands';
    }
    return getColorIdentity(colors);
}
exports.SORTS = [
    'Artist',
    'Mana Value',
    'Mana Value 2',
    'Mana Value Full',
    'Color Category',
    'Color Category Full',
    'Color Count',
    'Color Identity',
    'Color Identity Full',
    'Color Combination Includes',
    'Includes Color Combination',
    'Color',
    'Creature/Non-Creature',
    'Date Added',
    'Elo',
    'Finish',
    'Guilds',
    'Legality',
    'Loyalty',
    'Manacost Type',
    'Popularity',
    'Power',
    'Price USD',
    'Price USD Foil',
    'Price EUR',
    'MTGO TIX',
    'Rarity',
    'Set',
    'Shards / Wedges',
    'Status',
    'Subtype',
    'Supertype',
    'Tags',
    'Toughness',
    'Type',
    'Types-Multicolor',
    'Devotion to White',
    'Devotion to Blue',
    'Devotion to Black',
    'Devotion to Red',
    'Devotion to Green',
    'Unsorted',
];
exports.ORDERED_SORTS = [
    'Alphabetical',
    'Mana Value',
    'Price',
    'Elo',
    'Release date',
    'Cube Count',
    'Pick Count',
    'Collector number',
];
exports.SortFunctions = {
    Alphabetical: Util_1.alphaCompare,
    'Mana Value': (a, b) => (0, cardutil_1.cardCmc)(a) - (0, cardutil_1.cardCmc)(b),
    Price: (a, b) => ((0, cardutil_1.cardPrice)(a) ?? 0) - ((0, cardutil_1.cardPrice)(b) ?? 0),
    Elo: (a, b) => (0, cardutil_1.cardElo)(a) - (0, cardutil_1.cardElo)(b),
    'Release date': (a, b) => {
        if ((0, cardutil_1.cardReleaseDate)(a) > (0, cardutil_1.cardReleaseDate)(b)) {
            return 1;
        }
        if ((0, cardutil_1.cardReleaseDate)(a) < (0, cardutil_1.cardReleaseDate)(b)) {
            return -1;
        }
        return 0;
    },
    'Cube Count': (a, b) => (0, cardutil_1.cardCubeCount)(a) - (0, cardutil_1.cardCubeCount)(b),
    'Pick Count': (a, b) => (0, cardutil_1.cardPickCount)(a) - (0, cardutil_1.cardPickCount)(b),
    'Collector number': (a, b) => {
        if ((0, cardutil_1.cardCollectorNumber)(a) > (0, cardutil_1.cardCollectorNumber)(b)) {
            return 1;
        }
        if ((0, cardutil_1.cardCollectorNumber)(a) < (0, cardutil_1.cardCollectorNumber)(b)) {
            return -1;
        }
        return 0;
    },
};
const SortFunctionsOnDetails = (sort) => (a, b) => exports.SortFunctions[sort]({ details: a }, { details: b });
exports.SortFunctionsOnDetails = SortFunctionsOnDetails;
const allDevotions = (cube, color) => {
    const counts = new Set();
    for (const card of cube) {
        counts.add((0, cardutil_1.cardDevotion)(card, color));
    }
    return [...counts].sort((a, b) => a - b).map((n) => n.toFixed(0));
};
const priceBuckets = [0.25, 0.5, 1, 2, 3, 4, 5, 7, 10, 15, 20, 25, 30, 40, 50, 75, 100];
// returns the price bucket label at the index designating the upper bound
// at index == 0, returns < lowest
// at index == length, returs >= highest
function priceBucketLabel(index, prefix) {
    if (index === 0) {
        return `< ${prefix}${priceBuckets[0]}`;
    }
    if (index === priceBuckets.length) {
        return `>= ${prefix}${priceBuckets[priceBuckets.length - 1]}`;
    }
    return `${prefix}${priceBuckets[index - 1]} - ${prefix}${priceBuckets[index] - 0.01}`;
}
function priceBucketIndex(price) {
    if (price < priceBuckets[0]) {
        return 0;
    }
    for (let i = 1; i < priceBuckets.length; i++) {
        if (price >= priceBuckets[i - 1] && price < priceBuckets[i]) {
            return i;
        }
    }
    // Last bucket catches any remaining prices
    return priceBuckets.length;
}
function getPriceBucket(price, prefix) {
    return priceBucketLabel(priceBucketIndex(price), prefix);
}
function getEloBucket(elo) {
    const bucketFloor = Math.floor(elo / 50) * 50;
    return `${bucketFloor}-${bucketFloor + 49}`;
}
function getLabelsRaw(cube, sort, showOther) {
    let ret = [];
    /* Start of sort Options */
    if (sort === 'Color Category') {
        //Slice creates a copy of the readonly COLOR_CATEGORIES array (as it is a const assertion), as a regular array
        ret = Card_1.COLOR_CATEGORIES.slice();
    }
    else if (sort === 'Color Category Full') {
        ret = SINGLE_COLOR.concat(['Colorless'])
            .concat(GUILDS)
            .concat(SHARDS_AND_WEDGES)
            .concat(FOUR_AND_FIVE_COLOR)
            .concat(['Lands']);
    }
    else if (sort === 'Color Identity') {
        ret = ['White', 'Blue', 'Black', 'Red', 'Green', 'Multicolored', 'Colorless'];
    }
    else if (sort === 'Color Identity Full') {
        ret = SINGLE_COLOR.concat(['Colorless']).concat(GUILDS).concat(SHARDS_AND_WEDGES).concat(FOUR_AND_FIVE_COLOR);
    }
    else if (sort === 'Color Combination Includes' || sort === 'Includes Color Combination') {
        ret = ['Colorless'].concat(SINGLE_COLOR).concat(GUILDS).concat(SHARDS_AND_WEDGES).concat(FOUR_AND_FIVE_COLOR);
    }
    else if (sort === 'Mana Value' || sort === 'CMC') {
        ret = ['0', '1', '2', '3', '4', '5', '6', '7', '8+'];
    }
    else if (sort === 'Mana Value 2') {
        ret = ['0-1', '2', '3', '4', '5', '6', '7+'];
    }
    else if (sort === 'Mana Value Full') {
        // All unique CMCs of cards in the cube, rounded to a half-integer
        ret =
            cube
                ?.map((card) => Math.round((0, cardutil_1.cardCmc)(card) * 2) / 2)
                ?.filter((n, i, arr) => arr.indexOf(n) === i)
                ?.sort((a, b) => a - b)
                ?.map((n) => n.toString()) ?? [];
    }
    else if (sort === 'Color') {
        ret = ['White', 'Blue', 'Black', 'Red', 'Green', 'Colorless'];
    }
    else if (sort === 'Type') {
        ret = CARD_TYPES.concat(['Other']);
    }
    else if (sort === 'Supertype') {
        ret = ['Snow', 'Legendary', 'Tribal', 'Basic', 'Elite', 'Host', 'Ongoing', 'World'];
    }
    else if (sort === 'Tags') {
        const tags = [];
        for (const card of cube || []) {
            for (const tag of card.tags || []) {
                if (tag.length > 0 && !tags.includes(tag)) {
                    tags.push(tag);
                }
            }
        }
        ret = tags.sort();
    }
    else if (sort === 'Date Added') {
        //Convert addedTmsp from a number (or sometimes a string) into Date objects, then to locale string for the labelling and grouping
        const days = (cube ?? [])
            .map((card) => (0, cardutil_1.cardAddedTime)(card) ?? new Date(0))
            .map((date) => date.toLocaleDateString('en-US'));
        //Remove duplicates from days using Set
        const uniqueDays = [...new Set(days)];
        //Now sort the unique locale strings in order using their date timestamps, as string sorting dates is not well defined
        uniqueDays.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
        ret = uniqueDays;
    }
    else if (sort === 'Status') {
        ret = Card_1.CARD_STATUSES.slice();
    }
    else if (sort === 'Finish') {
        ret = ['Non-foil', 'Foil', 'Etched'];
    }
    else if (sort === 'Guilds') {
        ret = GUILDS;
    }
    else if (sort === 'Shards / Wedges') {
        ret = SHARDS_AND_WEDGES;
    }
    else if (sort === 'Color Count') {
        ret = ['0', '1', '2', '3', '4', '5'];
    }
    else if (sort === 'Set') {
        const sets = [];
        for (const card of cube || []) {
            const set = (0, cardutil_1.cardSet)(card).toUpperCase();
            if (!sets.includes(set)) {
                sets.push(set);
            }
        }
        ret = sets.sort();
    }
    else if (sort === 'Artist') {
        const artists = [];
        for (const card of cube || []) {
            if (!artists.includes((0, cardutil_1.cardArtist)(card))) {
                artists.push((0, cardutil_1.cardArtist)(card));
            }
        }
        ret = artists.sort();
    }
    else if (sort === 'Rarity') {
        ret = ['Common', 'Uncommon', 'Rare', 'Mythic', 'Special'];
    }
    else if (sort === 'Unsorted') {
        ret = ['All'];
    }
    else if (sort === 'Popularity') {
        ret = ['0–1%', '1–2%', '3–5%', '5–8%', '8–12%', '12–20%', '20–30%', '30–50%', '50–100%'];
    }
    else if (sort === 'Subtype') {
        const types = new Set();
        for (const card of cube || []) {
            const split = (card.type_line || '').split(/[-–—]/);
            if (split.length > 1) {
                const subtypes = split[1].trim().split(' ');
                const nonemptySubtypes = subtypes.filter((x) => x.trim());
                for (const subtype of nonemptySubtypes) {
                    types.add(subtype.trim());
                }
            }
        }
        ret = [...types];
    }
    else if (sort === 'Types-Multicolor') {
        ret = CARD_TYPES.filter((type) => type !== 'Land')
            .concat(GUILDS)
            .concat(SHARDS_AND_WEDGES)
            .concat(FOUR_AND_FIVE_COLOR)
            .concat(['Land', 'Other']);
    }
    else if (sort === 'Legality') {
        ret = ['Standard', 'Modern', 'Legacy', 'Vintage', 'Pioneer', 'Brawl', 'Historic', 'Pauper', 'Penny', 'Commander'];
    }
    else if (sort === 'Power') {
        const items = [];
        for (const card of cube || []) {
            if (card.details?.power) {
                if (!items.includes(card.details.power)) {
                    items.push(card.details.power);
                }
            }
        }
        ret = items.sort(defaultSort);
    }
    else if (sort === 'Toughness') {
        const items = [];
        for (const card of cube || []) {
            if (card.details?.toughness) {
                if (!items.includes(card.details.toughness)) {
                    items.push(card.details.toughness);
                }
            }
        }
        ret = items.sort(defaultSort);
    }
    else if (sort === 'Loyalty') {
        const items = [];
        for (const card of cube || []) {
            if (card.details?.loyalty) {
                if (!items.includes(card.details.loyalty)) {
                    items.push(card.details.loyalty);
                }
            }
        }
        ret = items.sort(defaultSort);
    }
    else if (sort === 'Manacost Type') {
        ret = ['Gold', 'Hybrid', 'Phyrexian'];
    }
    else if (sort === 'Creature/Non-Creature') {
        ret = ['Creature', 'Non-Creature'];
    }
    else if (['Price', 'Price USD', 'Price Foil', 'Price USD Foil'].includes(sort)) {
        const labels = [];
        for (let i = 0; i <= priceBuckets.length; i++) {
            labels.push(priceBucketLabel(i, '$'));
        }
        labels.push('No Price Available');
        ret = labels;
    }
    else if (sort === 'Price EUR') {
        const labels = [];
        for (let i = 0; i <= priceBuckets.length; i++) {
            labels.push(priceBucketLabel(i, '€'));
        }
        labels.push('No Price Available');
        ret = labels;
    }
    else if (sort === 'MTGO TIX') {
        const labels = [];
        for (let i = 0; i <= priceBuckets.length; i++) {
            labels.push(priceBucketLabel(i, ''));
        }
        labels.push('No Price Available');
        ret = labels;
    }
    else if (sort === 'Devotion to White') {
        ret = allDevotions(cube || [], 'W');
    }
    else if (sort === 'Devotion to Blue') {
        ret = allDevotions(cube || [], 'U');
    }
    else if (sort === 'Devotion to Black') {
        ret = allDevotions(cube || [], 'B');
    }
    else if (sort === 'Devotion to Red') {
        ret = allDevotions(cube || [], 'R');
    }
    else if (sort === 'Devotion to Green') {
        ret = allDevotions(cube || [], 'G');
    }
    else if (sort === 'Elo') {
        let elos = [];
        for (const card of cube || []) {
            const elo = (0, cardutil_1.cardElo)(card);
            if (!elos.includes(elo)) {
                elos.push(elo);
            }
        }
        elos = elos.sort((x, y) => (x < y ? -1 : 1));
        const buckets = elos.map(getEloBucket);
        const res = [];
        for (const bucket of buckets) {
            if (!res.includes(bucket)) {
                res.push(bucket);
            }
        }
        ret = res;
    }
    /* End of sort options */
    // whitespace around 'Other' to prevent collisions
    return showOther ? [...ret, ' Other '] : ret;
}
function cardGetLabels(card, sort, showOther = false) {
    let ret = [];
    /* Start of sort options */
    if (sort === 'Color Category') {
        const convertedColorCategory = (0, cardutil_1.convertFromLegacyCardColorCategory)(card.colorCategory);
        ret = [convertedColorCategory ?? GetColorCategory((0, cardutil_1.cardType)(card), (0, cardutil_1.cardColorIdentity)(card))];
    }
    else if (sort === 'Color Category Full') {
        const convertedColorCategory = (0, cardutil_1.convertFromLegacyCardColorCategory)(card.colorCategory);
        const colorCategory = convertedColorCategory ?? GetColorCategory((0, cardutil_1.cardType)(card), (0, cardutil_1.cardColorIdentity)(card));
        if (colorCategory === 'Multicolored') {
            ret = [getColorCombination((0, cardutil_1.cardColorIdentity)(card))];
        }
        else {
            ret = [colorCategory];
        }
    }
    else if (sort === 'Color Identity') {
        ret = [getColorIdentity((0, cardutil_1.cardColorIdentity)(card))];
    }
    else if (sort === 'Color Identity Full') {
        ret = [getColorCombination((0, cardutil_1.cardColorIdentity)(card))];
    }
    else if (sort === 'Color Combination Includes') {
        ret = cardutil_1.COLOR_COMBINATIONS.filter((comb) => (0, Util_1.arrayIsSubset)((0, cardutil_1.cardColorIdentity)(card), comb)).map(getColorCombination);
    }
    else if (sort === 'Includes Color Combination') {
        ret = cardutil_1.COLOR_COMBINATIONS.filter((comb) => (0, Util_1.arrayIsSubset)(comb, (0, cardutil_1.cardColorIdentity)(card))).map(getColorCombination);
    }
    else if (sort === 'Color') {
        const colors = (0, cardutil_1.cardColors)(card);
        if (colors.length === 0) {
            ret = ['Colorless'];
        }
        else {
            ret = colors.map((c) => COLOR_MAP[c]).filter((c) => c);
        }
    }
    else if (sort === '4+ Color') {
        if ((0, cardutil_1.cardColorIdentity)(card).length === 5) {
            ret = ['Five Color'];
        }
        else if ((0, cardutil_1.cardColorIdentity)(card).length === 4) {
            ret = [...'WUBRG'].filter((c) => !(0, cardutil_1.cardColorIdentity)(card).includes(c)).map((c) => `Non-${COLOR_MAP[c]}`);
        }
    }
    else if (sort === 'Mana Value' || sort === 'CMC') {
        // Sort by CMC, but collapse all >= 8 into '8+' category.
        const cmc = Math.round((0, cardutil_1.cardCmc)(card));
        if (cmc >= 8) {
            ret = ['8+'];
        }
        else {
            ret = [cmc.toString()];
        }
    }
    else if (sort === 'Mana Value 2') {
        const cmc = Math.round((0, cardutil_1.cardCmc)(card));
        if (cmc >= 7) {
            ret = ['7+'];
        }
        else if (cmc <= 1) {
            ret = ['0-1'];
        }
        else {
            ret = [cmc.toString()];
        }
    }
    else if (sort === 'Mana Value Full') {
        // Round to half-integer.
        ret = [(Math.round((0, cardutil_1.cardCmc)(card) * 2) / 2).toString()];
    }
    else if (sort === 'Supertype' || sort === 'Type') {
        const split = (0, cardutil_1.cardType)(card).split(/[-–—]/);
        let types;
        if (split.length > 1) {
            types = split[0]
                .trim()
                .split(' ')
                .map((x) => x.trim())
                .filter((x) => x);
        }
        else {
            types = (0, cardutil_1.cardType)(card)
                .trim()
                .split(' ')
                .map((x) => x.trim())
                .filter((x) => x);
        }
        if (types.includes('Contraption')) {
            ret = ['Contraption'];
        }
        else if (types.includes('Plane')) {
            ret = ['Plane'];
        }
        else {
            const labels = getLabelsRaw(null, sort, showOther);
            ret = types.filter((t) => labels.includes(t));
        }
    }
    else if (sort === 'Tags') {
        ret = card.tags || [];
    }
    else if (sort === 'Status') {
        ret = [(0, cardutil_1.cardStatus)(card)];
    }
    else if (sort === 'Finish') {
        ret = [(0, cardutil_1.cardFinish)(card)];
    }
    else if (sort === 'Date Added') {
        ret = [((0, cardutil_1.cardAddedTime)(card) ?? new Date(0)).toLocaleDateString('en-US')];
    }
    else if (sort === 'Guilds') {
        if ((0, cardutil_1.cardColorIdentity)(card).length === 2) {
            const ordered = [...'WUBRG'].filter((c) => (0, cardutil_1.cardColorIdentity)(card).includes(c)).join('');
            ret = [GUILD_MAP[ordered]];
        }
    }
    else if (sort === 'Shards / Wedges') {
        if ((0, cardutil_1.cardColorIdentity)(card).length === 3) {
            const ordered = [...'WUBRG'].filter((c) => (0, cardutil_1.cardColorIdentity)(card).includes(c)).join('');
            ret = [SHARD_AND_WEDGE_MAP[ordered]];
        }
    }
    else if (sort === 'Color Count') {
        ret = [(0, cardutil_1.cardColorIdentity)(card).length.toFixed(0)];
    }
    else if (sort === 'Set') {
        ret = [(0, cardutil_1.cardSet)(card).toUpperCase()];
    }
    else if (sort === 'Rarity') {
        const rarity = (0, cardutil_1.cardRarity)(card);
        ret = [rarity[0].toUpperCase() + rarity.slice(1)];
    }
    else if (sort === 'Subtype') {
        const split = (0, cardutil_1.cardType)(card).split(/[-–—]/);
        if (split.length > 1) {
            const subtypes = split[1].trim().split(' ');
            ret = subtypes.map((subtype) => subtype.trim()).filter((x) => x);
        }
    }
    else if (sort === 'Types-Multicolor') {
        if ((0, cardutil_1.cardColorIdentity)(card).length <= 1) {
            const split = (0, cardutil_1.cardType)(card).split('—');
            const types = split[0].trim().split(' ');
            const type = types[types.length - 1];
            // check last type
            if (![
                'Creature',
                'Planeswalker',
                'Instant',
                'Sorcery',
                'Artifact',
                'Enchantment',
                'Conspiracy',
                'Contraption',
                'Phenomenon',
                'Plane',
                'Scheme',
                'Vanguard',
                'Land',
                'Battle',
            ].includes(type)) {
                ret = ['Other'];
            }
            else {
                ret = [type];
            }
        }
        else if ((0, cardutil_1.cardColorIdentity)(card).length === 5) {
            ret = ['Five Color'];
        }
        else if ((0, cardutil_1.cardColorIdentity)(card).length === 4) {
            ret = [...'WUBRG'].filter((c) => !(0, cardutil_1.cardColorIdentity)(card).includes(c)).map((c) => `Non-${COLOR_MAP[c]}`);
        }
        else if ((0, cardutil_1.cardColorIdentity)(card).length === 3) {
            const ordered = [...'WUBRG'].filter((c) => (0, cardutil_1.cardColorIdentity)(card).includes(c)).join('');
            ret = [SHARD_AND_WEDGE_MAP[ordered]];
        }
        else if ((0, cardutil_1.cardColorIdentity)(card).length === 2) {
            const ordered = [...'WUBRG'].filter((c) => (0, cardutil_1.cardColorIdentity)(card).includes(c)).join('');
            ret = [GUILD_MAP[ordered]];
        }
    }
    else if (sort === 'Artist') {
        ret = [(0, cardutil_1.cardArtist)(card)];
    }
    else if (sort === 'Legality') {
        ret = Object.entries(card.details?.legalities ?? {})
            .filter(([, v]) => ['legal', 'banned'].includes(v))
            .map(([k]) => k);
    }
    else if (sort === 'Power') {
        if (card.details?.power) {
            ret = [card.details?.power];
        }
    }
    else if (sort === 'Toughness') {
        if (card.details?.toughness) {
            ret = [card.details?.toughness];
        }
    }
    else if (sort === 'Loyalty') {
        if (card.details?.loyalty) {
            ret = [card.details?.loyalty ?? '0'];
        }
    }
    else if (sort === 'Manacost Type') {
        const colors = (0, cardutil_1.cardColors)(card);
        const parsedCost = card.details?.parsed_cost ?? [];
        if (colors.length > 1 && parsedCost.every((symbol) => !symbol.includes('-'))) {
            ret = ['Gold'];
        }
        else if (colors.length > 1 &&
            parsedCost.some((symbol) => symbol.includes('-') && !symbol.includes('-p'))) {
            ret = ['Hybrid'];
        }
        else if (parsedCost.some((symbol) => symbol.includes('-p'))) {
            ret = ['Phyrexian'];
        }
    }
    else if (sort === 'Creature/Non-Creature') {
        ret = (0, cardutil_1.cardType)(card).toLowerCase().includes('creature') ? ['Creature'] : ['Non-Creature'];
    }
    else if (sort === 'Price USD' || sort === 'Price') {
        const price = card.details?.prices.usd ?? card.details?.prices.usd_foil;
        if (price) {
            ret = [getPriceBucket(price, '$')];
        }
        else {
            ret = ['No Price Available'];
        }
    }
    else if (sort === 'Price USD Foil') {
        const price = card.details?.prices.usd_foil;
        if (price) {
            ret = [getPriceBucket(price, '$')];
        }
        else {
            ret = ['No Price Available'];
        }
    }
    else if (sort === 'Price EUR') {
        const price = (0, cardutil_1.cardPriceEur)(card);
        if (price) {
            ret = [getPriceBucket(price, '€')];
        }
        else {
            ret = ['No Price Available'];
        }
    }
    else if (sort === 'MTGO TIX') {
        const price = (0, cardutil_1.cardTix)(card);
        if (price) {
            ret = [getPriceBucket(price, '')];
        }
        else {
            ret = ['No Price Available'];
        }
    }
    else if (sort === 'Devotion to White') {
        ret = [(0, cardutil_1.cardDevotion)(card, 'w').toString()];
    }
    else if (sort === 'Devotion to Blue') {
        ret = [(0, cardutil_1.cardDevotion)(card, 'u').toString()];
    }
    else if (sort === 'Devotion to Black') {
        ret = [(0, cardutil_1.cardDevotion)(card, 'b').toString()];
    }
    else if (sort === 'Devotion to Red') {
        ret = [(0, cardutil_1.cardDevotion)(card, 'r').toString()];
    }
    else if (sort === 'Devotion to Green') {
        ret = [(0, cardutil_1.cardDevotion)(card, 'g').toString()];
    }
    else if (sort === 'Unsorted') {
        ret = ['All'];
    }
    else if (sort === 'Popularity') {
        const popularity = (0, cardutil_1.cardPopularity)(card);
        if (popularity < 1)
            ret = ['0–1%'];
        else if (popularity < 2)
            ret = ['1–2%'];
        else if (popularity < 5)
            ret = ['3–5%'];
        else if (popularity < 8)
            ret = ['5–8%'];
        else if (popularity < 12)
            ret = ['8–12%'];
        else if (popularity < 20)
            ret = ['12–20%'];
        else if (popularity < 30)
            ret = ['20–30%'];
        else if (popularity < 50)
            ret = ['30–50%'];
        else if (popularity <= 100)
            ret = ['50–100%'];
    }
    else if (sort === 'Elo') {
        ret = [getEloBucket((0, cardutil_1.cardElo)(card))];
    }
    /* End of sort options */
    if (showOther && ret.length === 0) {
        // whitespace around 'Other' to prevent collisions
        ret = [' Other '];
    }
    return ret;
}
function cardCanBeSorted(card, sort) {
    return cardGetLabels(card, sort, false).length !== 0;
}
function cardIsLabel(card, label, sort) {
    return cardGetLabels(card, sort, false).includes(label);
}
function formatLabel(label) {
    return label ?? 'unknown';
}
// Get labels in string form.
function getLabels(cube, sort, showOther = false) {
    return getLabelsRaw(cube, sort, showOther).map(formatLabel);
}
function sortGroupsOrdered(cards, sort, showOther) {
    const labels = getLabelsRaw(cards, sort, showOther);
    const allCardLabels = cards.map((card) => [
        card,
        cardGetLabels(card, sort, showOther).map((label) => {
            if (labels.includes(label)) {
                return label;
            }
            return ' Other ';
        }),
    ]);
    const compare = (x, y) => labels.indexOf(x) - labels.indexOf(y);
    const byLabel = {};
    for (const [card, cardLabels] of allCardLabels) {
        if (cardLabels && cardLabels.length > 0) {
            cardLabels.sort(compare);
            for (const label of cardLabels) {
                if (!byLabel[label]) {
                    byLabel[label] = [];
                }
                byLabel[label].push(card);
            }
        }
    }
    return labels.filter((label) => byLabel[label]).map((label) => [formatLabel(label), byLabel[label]]);
}
function sortIntoGroups(cards, sort, showOther = false) {
    return (0, Util_1.fromEntries)(sortGroupsOrdered(cards, sort, showOther));
}
function sortDeep(cards, showOther, last, ...sorts) {
    if (sorts.length === 0) {
        return [...cards].sort(exports.SortFunctions[last]);
    }
    const [first, ...rest] = sorts;
    const nextSort = sortGroupsOrdered(cards, first ?? 'Unsorted', showOther);
    const result = [];
    for (const [label, group] of nextSort) {
        if (rest.length > 0) {
            result.push([label, sortDeep(group, showOther, last, ...rest)]);
        }
        else {
            result.push([label, group.sort(exports.SortFunctions[last])]);
        }
    }
    return result;
}
function isSimpleGroup(groups) {
    return groups.length === 0 || !Array.isArray(groups[0]);
}
function countGroup(group) {
    if (!isSimpleGroup(group)) {
        const counts = group.map(([, group2]) => countGroup(group2));
        return counts.reduce((a, b) => a + b, 0);
    }
    return group.length;
}
function accumulateCards(groups, accumulator) {
    if (groups.length === 0)
        return [];
    if (isSimpleGroup(groups)) {
        accumulator.push(...groups);
    }
    else {
        for (const [, group] of groups) {
            accumulateCards(group, accumulator);
        }
    }
}
function sortedCards(groups) {
    const accumulator = [];
    accumulateCards(groups, accumulator);
    return accumulator;
}
function sortForDownload(cards, primary = 'Color Category', secondary = 'Types-Multicolor', tertiary = 'Mana Value', quaternary = 'Alphabetical', showOther = false) {
    const groups = sortDeep(cards, showOther, quaternary, primary, secondary, tertiary);
    return sortedCards(groups);
}
