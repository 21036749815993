"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCardKingdomLink = exports.nameToDashedUrlComponent = exports.getCardHoarderLink = exports.getCardMarketLink = exports.tcgMassEntryUrl = exports.tcgplayerAffiliate = void 0;
exports.getTCGLink = getTCGLink;
const cardutil_1 = require("./cardutil");
exports.tcgplayerAffiliate = 'https://tcgplayer.pxf.io/c/5760114/1830156/21018';
exports.tcgMassEntryUrl = 'https://store.tcgplayer.com/massentry';
function getTCGLink(card) {
    if (card.details === undefined)
        return '#';
    const { name, isToken } = card.details;
    const tcgplayerId = card.details.tcgplayer_id;
    let tcgplayerLink = 'https://shop.tcgplayer.com/';
    if (tcgplayerId) {
        tcgplayerLink += `product/productsearch?id=${tcgplayerId}`;
    }
    else {
        const tcgplayerName = isToken ? `${name} Token` : name;
        tcgplayerLink += `productcatalog/product/show?ProductName=${tcgplayerName}`;
    }
    return `${exports.tcgplayerAffiliate}?u=${encodeURI(tcgplayerLink)}`;
}
const getCardMarketLink = (card) => `https://www.cardmarket.com/en/Magic/Products/Singles/${(0, cardutil_1.cardSetName)(card)
    .replace(/ /g, '-')
    .replace(/[:,."']/g, '')}/${(0, cardutil_1.cardName)(card).replace(/ /g, '-').replace(/:/g, '').replace(/\./g, '')}`;
exports.getCardMarketLink = getCardMarketLink;
const getCardHoarderLink = (card) => `https://www.cardhoarder.com/cards?data%5Bsearch%5D=${(0, cardutil_1.cardName)(card)}?affiliate_id=cubecobra&utm_source=cubecobra&utm_campaign=affiliate&utm_medium=card`;
exports.getCardHoarderLink = getCardHoarderLink;
const ck = (str) => str
    .replace(' - ', '-')
    .replace(/ /g, '-')
    .replace(/[:,."']/g, '')
    .toLowerCase();
exports.nameToDashedUrlComponent = ck;
const getCardKingdomLink = (card) => `https://www.cardkingdom.com/mtg/${ck((0, cardutil_1.cardSetName)(card))}/${ck((0, cardutil_1.cardName)(card))}?partner=CubeCobra&utm_source=CubeCobra&utm_medium=affiliate&utm_campaign=CubeCobra`;
exports.getCardKingdomLink = getCardKingdomLink;
exports.default = { getTCGLink, tcgMassEntryUrl: exports.tcgMassEntryUrl, tcgplayerAffiliate: exports.tcgplayerAffiliate };
