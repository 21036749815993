function id(x) { return x[0]; }

const normalizeCombination = (combination) => combination.join('').toLowerCase().replace('c', '').split('');
const reversedSetOperation = (op, value) => {
  if (op.toString() === ':') {
    op = '<=';
  }
  return setOperation(op, value);
};


import {
  defaultOperation,
  stringOperation,
  stringContainOperation,
  nameStringOperation,
  equalityOperation,
  setOperation,
  rarityOperation,
  convertParsedCost,
  manaCostOperation,
  castableCostOperation,
  setElementOperation,
  setCountOperation,
  devotionOperation,
} from '../../filtering/FuncOperations';
import {
  CARD_CATEGORY_DETECTORS,
  cardCmc,
  cardColors,
  cardColorIdentity,
  cardType,
  cardOracleText,
  cardSet,
  cardCollectorNumber,
  cardPower,
  cardToughness,
  cardTags,
  cardFinish,
  cardPrice,
  cardNormalPrice,
  cardFoilPrice,
  cardPriceEur,
  cardTix,
  cardNameLower,
  cardElo,
  cardPopularity,
  cardCubeCount,
  cardPickCount,
  cardArtist,
  cardLoyalty,
  cardRarity,
  cardStatus,
  cardCost,
  cardLayout,
  cardDevotion,
  cardLegalIn,
  cardBannedIn,
  cardRestrictedIn
} from '../../utils/cardutil';


const negated = (inner) => {
  const result = (card) => !inner(card);
  result.fieldsUsed = inner.fieldsUsed;
  return result;
};


const genericCondition = (propertyName, propertyAccessor, valuePred) => {
  const result = (card) => valuePred(propertyAccessor(card), card);
  result.fieldsUsed = [propertyName]
  return result;
};
var grammar = {
    Lexer: undefined,
    ParserRules: [
    {"name": "_$ebnf$1", "symbols": []},
    {"name": "_$ebnf$1", "symbols": ["_$ebnf$1", "wschar"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "_", "symbols": ["_$ebnf$1"], "postprocess": function(d) {return null;}},
    {"name": "__$ebnf$1", "symbols": ["wschar"]},
    {"name": "__$ebnf$1", "symbols": ["__$ebnf$1", "wschar"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "__", "symbols": ["__$ebnf$1"], "postprocess": function(d) {return null;}},
    {"name": "wschar", "symbols": [/[ \t\n\v\f]/], "postprocess": id},
    {"name": "filterStart", "symbols": ["_"], "postprocess":  () => {
          const result = () => true;
          result.fieldsUsed = [];
          return result;
        } },
    {"name": "filterStart", "symbols": ["_", "filter", "_"], "postprocess": ([, filter]) => filter},
    {"name": "filter", "symbols": ["filter", "__", "connector", "clause"], "postprocess":  ([clause1, , connectorFunc, clause2]) => {
          const result = connectorFunc(clause1, clause2);
          result.fieldsUsed = [...new Set(clause1.fieldsUsed.concat(clause2.fieldsUsed))];
          return result;
        } },
    {"name": "filter", "symbols": ["clause"], "postprocess": id},
    {"name": "clause$ebnf$1", "symbols": [{"literal":"-"}], "postprocess": id},
    {"name": "clause$ebnf$1", "symbols": [], "postprocess": function(d) {return null;}},
    {"name": "clause$subexpression$1", "symbols": [{"literal":"("}, "filter", {"literal":")"}], "postprocess": ([, f]) => [f]},
    {"name": "clause$subexpression$1", "symbols": ["condition"]},
    {"name": "clause", "symbols": ["clause$ebnf$1", "clause$subexpression$1"], "postprocess":  ([negation, [inner]]) => {
          if (negation) {
            return negated(inner);
          }
          return inner;
        } },
    {"name": "positiveHalfIntOpValue", "symbols": ["anyOperator", "positiveHalfIntValue"], "postprocess": ([op, value]) => defaultOperation(op, value)},
    {"name": "positiveHalfIntValue$ebnf$1", "symbols": [{"literal":"-"}], "postprocess": id},
    {"name": "positiveHalfIntValue$ebnf$1", "symbols": [], "postprocess": function(d) {return null;}},
    {"name": "positiveHalfIntValue$ebnf$2", "symbols": [/[0-9]/]},
    {"name": "positiveHalfIntValue$ebnf$2", "symbols": ["positiveHalfIntValue$ebnf$2", /[0-9]/], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "positiveHalfIntValue", "symbols": ["positiveHalfIntValue$ebnf$1", "positiveHalfIntValue$ebnf$2"], "postprocess": ([negative, digits]) => parseInt(`${negative || ''}${digits.join('')}`, 10)},
    {"name": "positiveHalfIntValue$ebnf$3", "symbols": []},
    {"name": "positiveHalfIntValue$ebnf$3", "symbols": ["positiveHalfIntValue$ebnf$3", /[0-9]/], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "positiveHalfIntValue$subexpression$1", "symbols": [{"literal":"0"}]},
    {"name": "positiveHalfIntValue$subexpression$1", "symbols": [{"literal":"5"}]},
    {"name": "positiveHalfIntValue", "symbols": ["positiveHalfIntValue$ebnf$3", {"literal":"."}, "positiveHalfIntValue$subexpression$1"], "postprocess": ([digits, , afterDecimal]) => parseFloat(`${digits.join('')}.${afterDecimal}`, 10)},
    {"name": "halfIntOpValue", "symbols": ["anyOperator", "halfIntValue"], "postprocess": ([op, value]) => defaultOperation(op, value)},
    {"name": "halfIntValue$ebnf$1", "symbols": [/[0-9]/]},
    {"name": "halfIntValue$ebnf$1", "symbols": ["halfIntValue$ebnf$1", /[0-9]/], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "halfIntValue$ebnf$2$subexpression$1$subexpression$1", "symbols": [{"literal":"0"}]},
    {"name": "halfIntValue$ebnf$2$subexpression$1$subexpression$1", "symbols": [{"literal":"5"}]},
    {"name": "halfIntValue$ebnf$2$subexpression$1", "symbols": [{"literal":"."}, "halfIntValue$ebnf$2$subexpression$1$subexpression$1"]},
    {"name": "halfIntValue$ebnf$2", "symbols": ["halfIntValue$ebnf$2$subexpression$1"], "postprocess": id},
    {"name": "halfIntValue$ebnf$2", "symbols": [], "postprocess": function(d) {return null;}},
    {"name": "halfIntValue", "symbols": ["halfIntValue$ebnf$1", "halfIntValue$ebnf$2"], "postprocess": ([digits, decimal]) => parseFloat(`${digits.join('')}${(decimal || []).join('')}`, 10)},
    {"name": "halfIntValue$subexpression$1", "symbols": [{"literal":"0"}]},
    {"name": "halfIntValue$subexpression$1", "symbols": [{"literal":"5"}]},
    {"name": "halfIntValue", "symbols": [{"literal":"."}, "halfIntValue$subexpression$1"], "postprocess": ([, digit]) => digit === '5' ? 0.5 : 0},
    {"name": "integerOpValue", "symbols": ["anyOperator", "integerValue"], "postprocess": ([op, value]) => defaultOperation(op, value)},
    {"name": "fetchedIntegerOpValue", "symbols": ["anyOperator", "integerValue"], "postprocess": ([op, value]) => fetchedOperation(op, value)},
    {"name": "integerValue$ebnf$1", "symbols": [/[0-9]/]},
    {"name": "integerValue$ebnf$1", "symbols": ["integerValue$ebnf$1", /[0-9]/], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "integerValue", "symbols": ["integerValue$ebnf$1"], "postprocess": ([digits]) => parseInt(digits.join(''), 10)},
    {"name": "dollarOpValue", "symbols": ["anyOperator", "dollarValue"], "postprocess": ([op, value]) => defaultOperation(op, value)},
    {"name": "dollarValue$ebnf$1", "symbols": [{"literal":"$"}], "postprocess": id},
    {"name": "dollarValue$ebnf$1", "symbols": [], "postprocess": function(d) {return null;}},
    {"name": "dollarValue$subexpression$1$ebnf$1", "symbols": [/[0-9]/]},
    {"name": "dollarValue$subexpression$1$ebnf$1", "symbols": ["dollarValue$subexpression$1$ebnf$1", /[0-9]/], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "dollarValue$subexpression$1", "symbols": ["dollarValue$subexpression$1$ebnf$1"]},
    {"name": "dollarValue$subexpression$1$ebnf$2", "symbols": [/[0-9]/]},
    {"name": "dollarValue$subexpression$1$ebnf$2", "symbols": ["dollarValue$subexpression$1$ebnf$2", /[0-9]/], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "dollarValue$subexpression$1", "symbols": ["dollarValue$subexpression$1$ebnf$2", {"literal":"."}, /[0-9]/]},
    {"name": "dollarValue$subexpression$1$ebnf$3", "symbols": [/[0-9]/]},
    {"name": "dollarValue$subexpression$1$ebnf$3", "symbols": ["dollarValue$subexpression$1$ebnf$3", /[0-9]/], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "dollarValue$subexpression$1", "symbols": ["dollarValue$subexpression$1$ebnf$3", {"literal":"."}, /[0-9]/, /[0-9]/]},
    {"name": "dollarValue", "symbols": ["dollarValue$ebnf$1", "dollarValue$subexpression$1"], "postprocess": ([, [digits, ...decimal]]) => parseFloat(digits.concat(decimal).join(''), 10)},
    {"name": "finishOpValue", "symbols": ["equalityOperator", "finishValue"], "postprocess": ([op, value]) => stringOperation(op.toString() === ':' ? '=' : op, value)},
    {"name": "finishValue$subexpression$1$subexpression$1", "symbols": [/[fF]/, /[oO]/, /[iI]/, /[lL]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "finishValue$subexpression$1", "symbols": ["finishValue$subexpression$1$subexpression$1"]},
    {"name": "finishValue$subexpression$1$subexpression$2", "symbols": [/[nN]/, /[oO]/, /[nN]/, {"literal":"-"}, /[fF]/, /[oO]/, /[iI]/, /[lL]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "finishValue$subexpression$1", "symbols": ["finishValue$subexpression$1$subexpression$2"]},
    {"name": "finishValue$subexpression$1$subexpression$3", "symbols": [/[eE]/, /[tT]/, /[cC]/, /[hH]/, /[eE]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "finishValue$subexpression$1", "symbols": ["finishValue$subexpression$1$subexpression$3"]},
    {"name": "finishValue", "symbols": ["finishValue$subexpression$1"], "postprocess": ([[finish]]) => finish.toLowerCase()},
    {"name": "finishValue$subexpression$2$subexpression$1", "symbols": [/[fF]/, /[oO]/, /[iI]/, /[lL]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "finishValue$subexpression$2", "symbols": ["finishValue$subexpression$2$subexpression$1"]},
    {"name": "finishValue$subexpression$2$subexpression$2", "symbols": [/[nN]/, /[oO]/, /[nN]/, {"literal":"-"}, /[fF]/, /[oO]/, /[iI]/, /[lL]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "finishValue$subexpression$2", "symbols": ["finishValue$subexpression$2$subexpression$2"]},
    {"name": "finishValue$subexpression$2$subexpression$3", "symbols": [/[eE]/, /[tT]/, /[cC]/, /[hH]/, /[eE]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "finishValue$subexpression$2", "symbols": ["finishValue$subexpression$2$subexpression$3"]},
    {"name": "finishValue", "symbols": [{"literal":"\""}, "finishValue$subexpression$2", {"literal":"\""}], "postprocess": ([, [finish]]) => finish.toLowerCase()},
    {"name": "legalityOpValue", "symbols": ["equalityOperator", "legalityValue"], "postprocess": ([op, value]) => setElementOperation(op, value)},
    {"name": "legalityValue$subexpression$1$subexpression$1", "symbols": [/[sS]/, /[tT]/, /[aA]/, /[nN]/, /[dD]/, /[aA]/, /[rR]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "legalityValue$subexpression$1", "symbols": ["legalityValue$subexpression$1$subexpression$1"]},
    {"name": "legalityValue$subexpression$1$subexpression$2", "symbols": [/[pP]/, /[iI]/, /[oO]/, /[nN]/, /[eE]/, /[eE]/, /[rR]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "legalityValue$subexpression$1", "symbols": ["legalityValue$subexpression$1$subexpression$2"]},
    {"name": "legalityValue$subexpression$1$subexpression$3", "symbols": [/[mM]/, /[oO]/, /[dD]/, /[eE]/, /[rR]/, /[nN]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "legalityValue$subexpression$1", "symbols": ["legalityValue$subexpression$1$subexpression$3"]},
    {"name": "legalityValue$subexpression$1$subexpression$4", "symbols": [/[lL]/, /[eE]/, /[gG]/, /[aA]/, /[cC]/, /[yY]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "legalityValue$subexpression$1", "symbols": ["legalityValue$subexpression$1$subexpression$4"]},
    {"name": "legalityValue$subexpression$1$subexpression$5", "symbols": [/[vV]/, /[iI]/, /[nN]/, /[tT]/, /[aA]/, /[gG]/, /[eE]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "legalityValue$subexpression$1", "symbols": ["legalityValue$subexpression$1$subexpression$5"]},
    {"name": "legalityValue$subexpression$1$subexpression$6", "symbols": [/[bB]/, /[rR]/, /[aA]/, /[wW]/, /[lL]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "legalityValue$subexpression$1", "symbols": ["legalityValue$subexpression$1$subexpression$6"]},
    {"name": "legalityValue$subexpression$1$subexpression$7", "symbols": [/[hH]/, /[iI]/, /[sS]/, /[tT]/, /[oO]/, /[rR]/, /[iI]/, /[cC]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "legalityValue$subexpression$1", "symbols": ["legalityValue$subexpression$1$subexpression$7"]},
    {"name": "legalityValue$subexpression$1$subexpression$8", "symbols": [/[pP]/, /[aA]/, /[uU]/, /[pP]/, /[eE]/, /[rR]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "legalityValue$subexpression$1", "symbols": ["legalityValue$subexpression$1$subexpression$8"]},
    {"name": "legalityValue$subexpression$1$subexpression$9", "symbols": [/[pP]/, /[eE]/, /[nN]/, /[nN]/, /[yY]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "legalityValue$subexpression$1", "symbols": ["legalityValue$subexpression$1$subexpression$9"]},
    {"name": "legalityValue$subexpression$1$subexpression$10", "symbols": [/[cC]/, /[oO]/, /[mM]/, /[mM]/, /[aA]/, /[nN]/, /[dD]/, /[eE]/, /[rR]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "legalityValue$subexpression$1", "symbols": ["legalityValue$subexpression$1$subexpression$10"]},
    {"name": "legalityValue", "symbols": ["legalityValue$subexpression$1"], "postprocess": ([[legality]]) => legality.toLowerCase()},
    {"name": "legalityValue$subexpression$2$subexpression$1", "symbols": [/[sS]/, /[tT]/, /[aA]/, /[nN]/, /[dD]/, /[aA]/, /[rR]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "legalityValue$subexpression$2", "symbols": ["legalityValue$subexpression$2$subexpression$1"]},
    {"name": "legalityValue$subexpression$2$subexpression$2", "symbols": [/[pP]/, /[iI]/, /[oO]/, /[nN]/, /[eE]/, /[eE]/, /[rR]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "legalityValue$subexpression$2", "symbols": ["legalityValue$subexpression$2$subexpression$2"]},
    {"name": "legalityValue$subexpression$2$subexpression$3", "symbols": [/[mM]/, /[oO]/, /[dD]/, /[eE]/, /[rR]/, /[nN]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "legalityValue$subexpression$2", "symbols": ["legalityValue$subexpression$2$subexpression$3"]},
    {"name": "legalityValue$subexpression$2$subexpression$4", "symbols": [/[lL]/, /[eE]/, /[gG]/, /[aA]/, /[cC]/, /[yY]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "legalityValue$subexpression$2", "symbols": ["legalityValue$subexpression$2$subexpression$4"]},
    {"name": "legalityValue$subexpression$2$subexpression$5", "symbols": [/[vV]/, /[iI]/, /[nN]/, /[tT]/, /[aA]/, /[gG]/, /[eE]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "legalityValue$subexpression$2", "symbols": ["legalityValue$subexpression$2$subexpression$5"]},
    {"name": "legalityValue$subexpression$2$subexpression$6", "symbols": [/[bB]/, /[rR]/, /[aA]/, /[wW]/, /[lL]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "legalityValue$subexpression$2", "symbols": ["legalityValue$subexpression$2$subexpression$6"]},
    {"name": "legalityValue$subexpression$2$subexpression$7", "symbols": [/[hH]/, /[iI]/, /[sS]/, /[tT]/, /[oO]/, /[rR]/, /[iI]/, /[cC]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "legalityValue$subexpression$2", "symbols": ["legalityValue$subexpression$2$subexpression$7"]},
    {"name": "legalityValue$subexpression$2$subexpression$8", "symbols": [/[pP]/, /[aA]/, /[uU]/, /[pP]/, /[eE]/, /[rR]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "legalityValue$subexpression$2", "symbols": ["legalityValue$subexpression$2$subexpression$8"]},
    {"name": "legalityValue$subexpression$2$subexpression$9", "symbols": [/[pP]/, /[eE]/, /[nN]/, /[nN]/, /[yY]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "legalityValue$subexpression$2", "symbols": ["legalityValue$subexpression$2$subexpression$9"]},
    {"name": "legalityValue$subexpression$2$subexpression$10", "symbols": [/[cC]/, /[oO]/, /[mM]/, /[mM]/, /[aA]/, /[nN]/, /[dD]/, /[eE]/, /[rR]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "legalityValue$subexpression$2", "symbols": ["legalityValue$subexpression$2$subexpression$10"]},
    {"name": "legalityValue", "symbols": [{"literal":"\""}, "legalityValue$subexpression$2", {"literal":"\""}], "postprocess": ([, [legality]]) => legality.toLowerCase()},
    {"name": "statusOpValue", "symbols": ["equalityOperator", "statusValue"], "postprocess": ([op, value]) => stringOperation(op.toString() === ':' ? '=' : op, value)},
    {"name": "statusValue$subexpression$1$subexpression$1", "symbols": [/[oO]/, /[wW]/, /[nN]/, /[eE]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "statusValue$subexpression$1", "symbols": ["statusValue$subexpression$1$subexpression$1"]},
    {"name": "statusValue$subexpression$1$subexpression$2", "symbols": [/[pP]/, /[rR]/, /[oO]/, /[xX]/, /[iI]/, /[eE]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "statusValue$subexpression$1", "symbols": ["statusValue$subexpression$1$subexpression$2"]},
    {"name": "statusValue$subexpression$1$subexpression$3", "symbols": [/[oO]/, /[rR]/, /[dD]/, /[eE]/, /[rR]/, /[eE]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "statusValue$subexpression$1", "symbols": ["statusValue$subexpression$1$subexpression$3"]},
    {"name": "statusValue$subexpression$1$subexpression$4", "symbols": [/[bB]/, /[oO]/, /[rR]/, /[rR]/, /[oO]/, /[wW]/, /[eE]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "statusValue$subexpression$1", "symbols": ["statusValue$subexpression$1$subexpression$4"]},
    {"name": "statusValue", "symbols": ["statusValue$subexpression$1"], "postprocess": ([[status]]) => status.toLowerCase()},
    {"name": "statusValue$subexpression$2$subexpression$1", "symbols": [/[oO]/, /[wW]/, /[nN]/, /[eE]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "statusValue$subexpression$2", "symbols": ["statusValue$subexpression$2$subexpression$1"]},
    {"name": "statusValue$subexpression$2$subexpression$2", "symbols": [/[pP]/, /[rR]/, /[oO]/, /[xX]/, /[iI]/, /[eE]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "statusValue$subexpression$2", "symbols": ["statusValue$subexpression$2$subexpression$2"]},
    {"name": "statusValue$subexpression$2$subexpression$3", "symbols": [/[oO]/, /[rR]/, /[dD]/, /[eE]/, /[rR]/, /[eE]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "statusValue$subexpression$2", "symbols": ["statusValue$subexpression$2$subexpression$3"]},
    {"name": "statusValue$subexpression$2$subexpression$4", "symbols": [/[nN]/, /[oO]/, /[tT]/, {"literal":" "}, /[oO]/, /[wW]/, /[nN]/, /[eE]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "statusValue$subexpression$2", "symbols": ["statusValue$subexpression$2$subexpression$4"]},
    {"name": "statusValue$subexpression$2$subexpression$5", "symbols": [/[pP]/, /[rR]/, /[eE]/, /[mM]/, /[iI]/, /[uU]/, /[mM]/, {"literal":" "}, /[oO]/, /[wW]/, /[nN]/, /[eE]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "statusValue$subexpression$2", "symbols": ["statusValue$subexpression$2$subexpression$5"]},
    {"name": "statusValue$subexpression$2$subexpression$6", "symbols": [/[bB]/, /[oO]/, /[rR]/, /[rR]/, /[oO]/, /[wW]/, /[eE]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "statusValue$subexpression$2", "symbols": ["statusValue$subexpression$2$subexpression$6"]},
    {"name": "statusValue", "symbols": [{"literal":"'"}, "statusValue$subexpression$2", {"literal":"'"}], "postprocess": ([, [status]]) => status.toLowerCase()},
    {"name": "statusValue$subexpression$3$subexpression$1", "symbols": [/[oO]/, /[wW]/, /[nN]/, /[eE]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "statusValue$subexpression$3", "symbols": ["statusValue$subexpression$3$subexpression$1"]},
    {"name": "statusValue$subexpression$3$subexpression$2", "symbols": [/[pP]/, /[rR]/, /[oO]/, /[xX]/, /[iI]/, /[eE]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "statusValue$subexpression$3", "symbols": ["statusValue$subexpression$3$subexpression$2"]},
    {"name": "statusValue$subexpression$3$subexpression$3", "symbols": [/[oO]/, /[rR]/, /[dD]/, /[eE]/, /[rR]/, /[eE]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "statusValue$subexpression$3", "symbols": ["statusValue$subexpression$3$subexpression$3"]},
    {"name": "statusValue$subexpression$3$subexpression$4", "symbols": [/[nN]/, /[oO]/, /[tT]/, {"literal":" "}, /[oO]/, /[wW]/, /[nN]/, /[eE]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "statusValue$subexpression$3", "symbols": ["statusValue$subexpression$3$subexpression$4"]},
    {"name": "statusValue$subexpression$3$subexpression$5", "symbols": [/[pP]/, /[rR]/, /[eE]/, /[mM]/, /[iI]/, /[uU]/, /[mM]/, {"literal":" "}, /[oO]/, /[wW]/, /[nN]/, /[eE]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "statusValue$subexpression$3", "symbols": ["statusValue$subexpression$3$subexpression$5"]},
    {"name": "statusValue$subexpression$3$subexpression$6", "symbols": [/[bB]/, /[oO]/, /[rR]/, /[rR]/, /[oO]/, /[wW]/, /[eE]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "statusValue$subexpression$3", "symbols": ["statusValue$subexpression$3$subexpression$6"]},
    {"name": "statusValue", "symbols": [{"literal":"\""}, "statusValue$subexpression$3", {"literal":"\""}], "postprocess": ([, [status]]) => status.toLowerCase()},
    {"name": "rarityOpValue", "symbols": ["anyOperator", "rarityValue"], "postprocess": ([op, value]) => rarityOperation(op, value)},
    {"name": "rarityValue$subexpression$1$subexpression$1", "symbols": [/[sS]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "rarityValue$subexpression$1", "symbols": ["rarityValue$subexpression$1$subexpression$1"]},
    {"name": "rarityValue$subexpression$1$subexpression$2", "symbols": [/[sS]/, /[pP]/, /[eE]/, /[cC]/, /[iI]/, /[aA]/, /[lL]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "rarityValue$subexpression$1", "symbols": ["rarityValue$subexpression$1$subexpression$2"]},
    {"name": "rarityValue$subexpression$1$subexpression$3", "symbols": [/[mM]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "rarityValue$subexpression$1", "symbols": ["rarityValue$subexpression$1$subexpression$3"]},
    {"name": "rarityValue$subexpression$1$subexpression$4", "symbols": [/[mM]/, /[yY]/, /[tT]/, /[hH]/, /[iI]/, /[cC]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "rarityValue$subexpression$1", "symbols": ["rarityValue$subexpression$1$subexpression$4"]},
    {"name": "rarityValue$subexpression$1$subexpression$5", "symbols": [/[rR]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "rarityValue$subexpression$1", "symbols": ["rarityValue$subexpression$1$subexpression$5"]},
    {"name": "rarityValue$subexpression$1$subexpression$6", "symbols": [/[rR]/, /[aA]/, /[rR]/, /[eE]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "rarityValue$subexpression$1", "symbols": ["rarityValue$subexpression$1$subexpression$6"]},
    {"name": "rarityValue$subexpression$1$subexpression$7", "symbols": [/[uU]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "rarityValue$subexpression$1", "symbols": ["rarityValue$subexpression$1$subexpression$7"]},
    {"name": "rarityValue$subexpression$1$subexpression$8", "symbols": [/[uU]/, /[nN]/, /[cC]/, /[oO]/, /[mM]/, /[mM]/, /[oO]/, /[nN]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "rarityValue$subexpression$1", "symbols": ["rarityValue$subexpression$1$subexpression$8"]},
    {"name": "rarityValue$subexpression$1$subexpression$9", "symbols": [/[cC]/, /[oO]/, /[mM]/, /[mM]/, /[oO]/, /[nN]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "rarityValue$subexpression$1", "symbols": ["rarityValue$subexpression$1$subexpression$9"]},
    {"name": "rarityValue$subexpression$1$subexpression$10", "symbols": [/[cC]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "rarityValue$subexpression$1", "symbols": ["rarityValue$subexpression$1$subexpression$10"]},
    {"name": "rarityValue", "symbols": ["rarityValue$subexpression$1"], "postprocess": ([[rarity]]) => rarity},
    {"name": "alphaNumericValue$ebnf$1", "symbols": [/[a-zA-Z0-9]/]},
    {"name": "alphaNumericValue$ebnf$1", "symbols": ["alphaNumericValue$ebnf$1", /[a-zA-Z0-9]/], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "alphaNumericValue", "symbols": ["alphaNumericValue$ebnf$1"], "postprocess": ([letters]) => letters.join('').toLowerCase()},
    {"name": "alphaNumericOpValue", "symbols": ["equalityOperator", "alphaNumericValue"], "postprocess": ([op, value]) => equalityOperation(op, value)},
    {"name": "colorCombinationOpValue", "symbols": ["anyOperator", "colorCombinationValue"], "postprocess": ([op, value]) => { const operation = setOperation(op, value); return (fieldValue) => operation(normalizeCombination(fieldValue)); }},
    {"name": "colorCombinationOpValue", "symbols": ["anyOperator", "integerValue"], "postprocess": ([op, value]) => { const operation = defaultOperation(op, value); return (fieldValue) => operation(normalizeCombination(fieldValue).length); }},
    {"name": "colorCombinationOpValue$subexpression$1", "symbols": [/[mM]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "colorCombinationOpValue", "symbols": ["anyOperator", "colorCombinationOpValue$subexpression$1"], "postprocess": ([op]) => { const operation = defaultOperation(op, 2); return (fieldValue) => operation(normalizeCombination(fieldValue).length); }},
    {"name": "colorIdentityOpValue", "symbols": ["anyOperator", "colorCombinationValue"], "postprocess": ([op, value]) => { const operation = reversedSetOperation(op, value); return (fieldValue) => operation(normalizeCombination(fieldValue)); }},
    {"name": "colorIdentityOpValue", "symbols": ["anyOperator", "integerValue"], "postprocess": ([op, value]) => { const operation = defaultOperation(op, value); return (fieldValue) => operation(normalizeCombination(fieldValue).length); }},
    {"name": "colorIdentityOpValue$subexpression$1", "symbols": [{"literal":"="}]},
    {"name": "colorIdentityOpValue$subexpression$1", "symbols": [{"literal":":"}]},
    {"name": "colorIdentityOpValue$subexpression$2", "symbols": [/[mM]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "colorIdentityOpValue", "symbols": ["colorIdentityOpValue$subexpression$1", "colorIdentityOpValue$subexpression$2"], "postprocess": ([op]) => { normalizeCombination(fieldValue).length > 1; }},
    {"name": "colorIdentityOpValue$subexpression$3$string$1", "symbols": [{"literal":"!"}, {"literal":"="}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "colorIdentityOpValue$subexpression$3", "symbols": ["colorIdentityOpValue$subexpression$3$string$1"]},
    {"name": "colorIdentityOpValue$subexpression$3$string$2", "symbols": [{"literal":"<"}, {"literal":">"}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "colorIdentityOpValue$subexpression$3", "symbols": ["colorIdentityOpValue$subexpression$3$string$2"]},
    {"name": "colorIdentityOpValue$subexpression$4", "symbols": [/[mM]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "colorIdentityOpValue", "symbols": ["colorIdentityOpValue$subexpression$3", "colorIdentityOpValue$subexpression$4"], "postprocess": ([op]) => { normalizeCombination(fieldValue).length < 2; }},
    {"name": "colorCombinationValue$subexpression$1$subexpression$1", "symbols": [/[cC]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "colorCombinationValue$subexpression$1", "symbols": ["colorCombinationValue$subexpression$1$subexpression$1"]},
    {"name": "colorCombinationValue$subexpression$1$subexpression$2", "symbols": [/[bB]/, /[rR]/, /[oO]/, /[wW]/, /[nN]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "colorCombinationValue$subexpression$1", "symbols": ["colorCombinationValue$subexpression$1$subexpression$2"]},
    {"name": "colorCombinationValue$subexpression$1$subexpression$3", "symbols": [/[cC]/, /[oO]/, /[lL]/, /[oO]/, /[rR]/, /[lL]/, /[eE]/, /[sS]/, /[sS]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "colorCombinationValue$subexpression$1", "symbols": ["colorCombinationValue$subexpression$1$subexpression$3"]},
    {"name": "colorCombinationValue", "symbols": ["colorCombinationValue$subexpression$1"], "postprocess": () => []},
    {"name": "colorCombinationValue$subexpression$2", "symbols": [/[wW]/, /[hH]/, /[iI]/, /[tT]/, /[eE]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "colorCombinationValue", "symbols": ["colorCombinationValue$subexpression$2"], "postprocess": () => ['w']},
    {"name": "colorCombinationValue$subexpression$3", "symbols": [/[bB]/, /[lL]/, /[uU]/, /[eE]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "colorCombinationValue", "symbols": ["colorCombinationValue$subexpression$3"], "postprocess": () => ['u']},
    {"name": "colorCombinationValue$subexpression$4", "symbols": [/[bB]/, /[lL]/, /[aA]/, /[cC]/, /[kK]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "colorCombinationValue", "symbols": ["colorCombinationValue$subexpression$4"], "postprocess": () => ['b']},
    {"name": "colorCombinationValue$subexpression$5", "symbols": [/[rR]/, /[eE]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "colorCombinationValue", "symbols": ["colorCombinationValue$subexpression$5"], "postprocess": () => ['r']},
    {"name": "colorCombinationValue$subexpression$6", "symbols": [/[gG]/, /[rR]/, /[eE]/, /[eE]/, /[nN]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "colorCombinationValue", "symbols": ["colorCombinationValue$subexpression$6"], "postprocess": () => ['g']},
    {"name": "colorCombinationValue$subexpression$7$subexpression$1", "symbols": [/[aA]/, /[zZ]/, /[oO]/, /[rR]/, /[iI]/, /[oO]/, /[uU]/, /[sS]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "colorCombinationValue$subexpression$7", "symbols": ["colorCombinationValue$subexpression$7$subexpression$1"]},
    {"name": "colorCombinationValue$subexpression$7$string$1", "symbols": [{"literal":"a"}, {"literal":"z"}, {"literal":"o"}, {"literal":"r"}, {"literal":"i"}, {"literal":"u"}, {"literal":"s"}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "colorCombinationValue$subexpression$7", "symbols": ["colorCombinationValue$subexpression$7$string$1"]},
    {"name": "colorCombinationValue", "symbols": ["colorCombinationValue$subexpression$7"], "postprocess": () => ['w', 'u']},
    {"name": "colorCombinationValue$subexpression$8", "symbols": [/[dD]/, /[iI]/, /[mM]/, /[iI]/, /[rR]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "colorCombinationValue", "symbols": ["colorCombinationValue$subexpression$8"], "postprocess": () => ['u', 'b']},
    {"name": "colorCombinationValue$subexpression$9", "symbols": [/[rR]/, /[aA]/, /[kK]/, /[dD]/, /[oO]/, /[sS]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "colorCombinationValue", "symbols": ["colorCombinationValue$subexpression$9"], "postprocess": () => ['b', 'r']},
    {"name": "colorCombinationValue$subexpression$10$subexpression$1", "symbols": [/[gG]/, /[rR]/, /[uU]/, /[uU]/, /[lL]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "colorCombinationValue$subexpression$10", "symbols": ["colorCombinationValue$subexpression$10$subexpression$1"]},
    {"name": "colorCombinationValue$subexpression$10$subexpression$2", "symbols": [/[gG]/, /[rR]/, /[uU]/, /[lL]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "colorCombinationValue$subexpression$10", "symbols": ["colorCombinationValue$subexpression$10$subexpression$2"]},
    {"name": "colorCombinationValue", "symbols": ["colorCombinationValue$subexpression$10"], "postprocess": () => ['r', 'g']},
    {"name": "colorCombinationValue$subexpression$11", "symbols": [/[sS]/, /[eE]/, /[lL]/, /[eE]/, /[sS]/, /[nN]/, /[yY]/, /[aA]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "colorCombinationValue", "symbols": ["colorCombinationValue$subexpression$11"], "postprocess": () => ['g', 'w']},
    {"name": "colorCombinationValue$subexpression$12", "symbols": [/[oO]/, /[rR]/, /[zZ]/, /[hH]/, /[oO]/, /[vV]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "colorCombinationValue", "symbols": ["colorCombinationValue$subexpression$12"], "postprocess": () => ['w', 'b']},
    {"name": "colorCombinationValue$subexpression$13$subexpression$1", "symbols": [/[iI]/, /[zZ]/, /[zZ]/, /[eE]/, /[tT]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "colorCombinationValue$subexpression$13", "symbols": ["colorCombinationValue$subexpression$13$subexpression$1"]},
    {"name": "colorCombinationValue$subexpression$13$subexpression$2", "symbols": [/[iI]/, /[zZ]/, /[eE]/, /[tT]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "colorCombinationValue$subexpression$13", "symbols": ["colorCombinationValue$subexpression$13$subexpression$2"]},
    {"name": "colorCombinationValue", "symbols": ["colorCombinationValue$subexpression$13"], "postprocess": () => ['u', 'r']},
    {"name": "colorCombinationValue$subexpression$14", "symbols": [/[gG]/, /[oO]/, /[lL]/, /[gG]/, /[aA]/, /[rR]/, /[iI]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "colorCombinationValue", "symbols": ["colorCombinationValue$subexpression$14"], "postprocess": () => ['b', 'g']},
    {"name": "colorCombinationValue$subexpression$15", "symbols": [/[bB]/, /[oO]/, /[rR]/, /[oO]/, /[sS]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "colorCombinationValue", "symbols": ["colorCombinationValue$subexpression$15"], "postprocess": () => ['w', 'r']},
    {"name": "colorCombinationValue$subexpression$16", "symbols": [/[sS]/, /[iI]/, /[mM]/, /[iI]/, /[cC]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "colorCombinationValue", "symbols": ["colorCombinationValue$subexpression$16"], "postprocess": () => ['u', 'g']},
    {"name": "colorCombinationValue$subexpression$17", "symbols": [/[bB]/, /[aA]/, /[nN]/, /[tT]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "colorCombinationValue", "symbols": ["colorCombinationValue$subexpression$17"], "postprocess": () => ['w', 'u', 'g']},
    {"name": "colorCombinationValue$subexpression$18", "symbols": [/[eE]/, /[sS]/, /[pP]/, /[eE]/, /[rR]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "colorCombinationValue", "symbols": ["colorCombinationValue$subexpression$18"], "postprocess": () => ['w', 'u', 'b']},
    {"name": "colorCombinationValue$subexpression$19", "symbols": [/[gG]/, /[rR]/, /[iI]/, /[xX]/, /[iI]/, /[sS]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "colorCombinationValue", "symbols": ["colorCombinationValue$subexpression$19"], "postprocess": () => ['u', 'b', 'r']},
    {"name": "colorCombinationValue$subexpression$20", "symbols": [/[jJ]/, /[uU]/, /[nN]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "colorCombinationValue", "symbols": ["colorCombinationValue$subexpression$20"], "postprocess": () => ['b', 'r', 'g']},
    {"name": "colorCombinationValue$subexpression$21", "symbols": [/[nN]/, /[aA]/, /[yY]/, /[aA]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "colorCombinationValue", "symbols": ["colorCombinationValue$subexpression$21"], "postprocess": () => ['w', 'r', 'g']},
    {"name": "colorCombinationValue$subexpression$22", "symbols": [/[mM]/, /[aA]/, /[rR]/, /[dD]/, /[uU]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "colorCombinationValue", "symbols": ["colorCombinationValue$subexpression$22"], "postprocess": () => ['w', 'b', 'r']},
    {"name": "colorCombinationValue$subexpression$23", "symbols": [/[tT]/, /[eE]/, /[mM]/, /[uU]/, /[rR]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "colorCombinationValue", "symbols": ["colorCombinationValue$subexpression$23"], "postprocess": () => ['u', 'r', 'g']},
    {"name": "colorCombinationValue$subexpression$24", "symbols": [/[aA]/, /[bB]/, /[zZ]/, /[aA]/, /[nN]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "colorCombinationValue", "symbols": ["colorCombinationValue$subexpression$24"], "postprocess": () => ['w', 'b', 'g']},
    {"name": "colorCombinationValue$subexpression$25", "symbols": [/[jJ]/, /[eE]/, /[sS]/, /[kK]/, /[aA]/, /[iI]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "colorCombinationValue", "symbols": ["colorCombinationValue$subexpression$25"], "postprocess": () => ['w', 'u', 'r']},
    {"name": "colorCombinationValue$subexpression$26", "symbols": [/[sS]/, /[uU]/, /[lL]/, /[tT]/, /[aA]/, /[iI]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "colorCombinationValue", "symbols": ["colorCombinationValue$subexpression$26"], "postprocess": () => ['u', 'b', 'g']},
    {"name": "colorCombinationValue$subexpression$27$subexpression$1", "symbols": [/[rR]/, /[aA]/, /[iI]/, /[nN]/, /[bB]/, /[oO]/, /[wW]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "colorCombinationValue$subexpression$27", "symbols": ["colorCombinationValue$subexpression$27$subexpression$1"]},
    {"name": "colorCombinationValue$subexpression$27$subexpression$2", "symbols": [/[fF]/, /[iI]/, /[vV]/, /[eE]/, /[cC]/, /[oO]/, /[lL]/, /[oO]/, /[rR]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "colorCombinationValue$subexpression$27", "symbols": ["colorCombinationValue$subexpression$27$subexpression$2"]},
    {"name": "colorCombinationValue", "symbols": ["colorCombinationValue$subexpression$27"], "postprocess": () => ['w', 'u', 'b', 'r', 'g']},
    {"name": "colorCombinationValue$macrocall$2$subexpression$1", "symbols": [/[wW]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "colorCombinationValue$macrocall$2", "symbols": ["colorCombinationValue$macrocall$2$subexpression$1"]},
    {"name": "colorCombinationValue$macrocall$3$subexpression$1", "symbols": [/[uU]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "colorCombinationValue$macrocall$3", "symbols": ["colorCombinationValue$macrocall$3$subexpression$1"]},
    {"name": "colorCombinationValue$macrocall$4$subexpression$1", "symbols": [/[bB]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "colorCombinationValue$macrocall$4", "symbols": ["colorCombinationValue$macrocall$4$subexpression$1"]},
    {"name": "colorCombinationValue$macrocall$5$subexpression$1", "symbols": [/[rR]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "colorCombinationValue$macrocall$5", "symbols": ["colorCombinationValue$macrocall$5$subexpression$1"]},
    {"name": "colorCombinationValue$macrocall$6$subexpression$1", "symbols": [/[gG]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "colorCombinationValue$macrocall$6", "symbols": ["colorCombinationValue$macrocall$6$subexpression$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$4"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$5"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$5", "symbols": ["colorCombinationValue$macrocall$6"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$4"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$5"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$4"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$2", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$2"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$3", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$2", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$2"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$6", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$4"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$4", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$6"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$6", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$3", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$4"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$8", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$4"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$7", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$8", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$8"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$7", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$4", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$7"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$2", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$6", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$2"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$7", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$4"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$8", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$5"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$7"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$8"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$2", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$2"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$3", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$6", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$5", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$6"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$6", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$8"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$4", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$6"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$5", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$5"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$6", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$7", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$4"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$8", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$7"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$9", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$8"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$7", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$9"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$8", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$8"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$9", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$7", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$8", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1$macrocall$7"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$3", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$5"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$10", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$2"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$11", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$12", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$5"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$11"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$12"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$2", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$2"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$3", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$10", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$5", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$10"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$6", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$12"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$4", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$6"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$5", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$5"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$6", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$11", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$4"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$8", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$11"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$9", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$12"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$7", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$9"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$8", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$8"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$9", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$7", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$12", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1$macrocall$7"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$4", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$9"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$14", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$2"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$15", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$16", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$4"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$15"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$16"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$2", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$2"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$3", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$14", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$5", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$14"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$6", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$16"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$4", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$6"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$5", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$5"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$6", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$15", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$4"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$8", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$15"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$9", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$16"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$7", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$9"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$8", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$8"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$9", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$7", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$16", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1$macrocall$7"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$5", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$13"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$1$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$2", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$7", "symbols": ["colorCombinationValue$macrocall$2"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$8", "symbols": ["colorCombinationValue$macrocall$4"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$9", "symbols": ["colorCombinationValue$macrocall$5"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$10", "symbols": ["colorCombinationValue$macrocall$6"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$8"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$9"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$10"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$4"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$2", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$2"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$3", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$2", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$5", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$2"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$6", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$4"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$4", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$6"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$5", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$5"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$6", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$3", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$4"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$8", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$9", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$4"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$7", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$9"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$8", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$8"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$9", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$7", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$4", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1$macrocall$7"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$7", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$6", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$7"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$7", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$9"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$8", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$10"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$7"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$8"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$2", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$2"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$3", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$6", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$5", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$6"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$6", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$8"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$4", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$6"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$5", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$5"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$6", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$7", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$4"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$8", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$7"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$9", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$8"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$7", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$9"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$8", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$8"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$9", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$7", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$8", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1$macrocall$7"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$8", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$5"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$10", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$7"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$11", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$8"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$12", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$10"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$11"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$12"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$2", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$2"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$3", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$10", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$5", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$10"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$6", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$12"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$4", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$6"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$5", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$5"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$6", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$11", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$4"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$8", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$11"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$9", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$12"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$7", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$9"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$8", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$8"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$9", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$7", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$12", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1$macrocall$7"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$9", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$9"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$14", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$7"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$15", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$8"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$16", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$9"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$15"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$16"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$2", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$2"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$3", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$14", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$5", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$14"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$6", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$16"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$4", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$6"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$5", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$5"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$6", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$15", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$4"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$8", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$15"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$9", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$16"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$7", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$9"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$8", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$8"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$9", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$7", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$16", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1$macrocall$7"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$10", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1$macrocall$13"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$6$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$3", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$6"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$12", "symbols": ["colorCombinationValue$macrocall$2"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$13", "symbols": ["colorCombinationValue$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$14", "symbols": ["colorCombinationValue$macrocall$5"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$15", "symbols": ["colorCombinationValue$macrocall$6"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$13"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$14"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$15"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$4"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$2", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$2"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$3", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$2", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$5", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$2"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$6", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$4"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$4", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$6"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$5", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$5"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$6", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$3", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$4"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$8", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$9", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$4"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$7", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$9"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$8", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$8"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$9", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$7", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$4", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1$macrocall$7"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$12", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$6", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$12"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$7", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$14"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$8", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$15"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$7"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$8"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$2", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$2"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$3", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$6", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$5", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$6"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$6", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$8"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$4", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$6"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$5", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$5"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$6", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$7", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$4"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$8", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$7"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$9", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$8"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$7", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$9"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$8", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$8"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$9", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$7", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$8", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1$macrocall$7"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$13", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$5"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$10", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$12"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$11", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$13"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$12", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$15"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$11"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$12"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$2", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$2"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$3", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$10", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$5", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$10"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$6", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$12"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$4", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$6"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$5", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$5"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$6", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$11", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$4"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$8", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$11"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$9", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$12"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$7", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$9"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$8", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$8"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$9", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$7", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$12", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1$macrocall$7"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$14", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$9"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$14", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$12"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$15", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$13"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$16", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$14"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$15"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$16"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$2", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$2"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$3", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$14", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$5", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$14"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$6", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$16"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$4", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$6"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$5", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$5"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$6", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$15", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$4"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$8", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$15"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$9", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$16"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$7", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$9"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$8", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$8"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$9", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$7", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$16", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1$macrocall$7"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$15", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1$macrocall$13"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$11$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$4", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$11"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$17", "symbols": ["colorCombinationValue$macrocall$2"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$18", "symbols": ["colorCombinationValue$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$19", "symbols": ["colorCombinationValue$macrocall$4"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$20", "symbols": ["colorCombinationValue$macrocall$6"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$18"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$19"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$20"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$4"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$2", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$2"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$3", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$2", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$5", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$2"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$6", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$4"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$4", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$6"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$5", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$5"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$6", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$3", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$4"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$8", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$9", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$4"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$7", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$9"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$8", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$8"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$9", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$7", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$4", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1$macrocall$7"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$17", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$6", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$17"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$7", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$19"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$8", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$20"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$7"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$8"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$2", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$2"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$3", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$6", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$5", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$6"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$6", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$8"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$4", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$6"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$5", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$5"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$6", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$7", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$4"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$8", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$7"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$9", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$8"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$7", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$9"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$8", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$8"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$9", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$7", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$8", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1$macrocall$7"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$18", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$5"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$10", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$17"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$11", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$18"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$12", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$20"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$11"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$12"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$2", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$2"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$3", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$10", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$5", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$10"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$6", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$12"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$4", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$6"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$5", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$5"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$6", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$11", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$4"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$8", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$11"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$9", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$12"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$7", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$9"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$8", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$8"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$9", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$7", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$12", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1$macrocall$7"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$19", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$9"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$14", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$17"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$15", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$18"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$16", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$19"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$15"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$16"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$2", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$2"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$3", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$14", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$5", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$14"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$6", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$16"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$4", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$6"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$5", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$5"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$6", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$15", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$4"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$8", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$15"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$9", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$16"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$7", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$9"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$8", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$8"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$9", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$7", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$16", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1$macrocall$7"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$20", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1$macrocall$13"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$16$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$5", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$16"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$22", "symbols": ["colorCombinationValue$macrocall$2"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$23", "symbols": ["colorCombinationValue$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$24", "symbols": ["colorCombinationValue$macrocall$4"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$25", "symbols": ["colorCombinationValue$macrocall$5"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$23"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$24"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$25"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$4"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$2", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$2"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$3", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$1$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$2", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$5", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$2"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$6", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$4"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$4", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$6"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$5", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$5"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$6", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$4$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$3", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$4"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$8", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$9", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$4"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$7", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$9"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$8", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$8"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$9", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$7", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$7$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$4", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1$macrocall$7"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$22", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$6", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$22"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$7", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$24"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$8", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$25"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$7"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$8"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$2", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$2"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$3", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$1$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$6", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$5", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$6"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$6", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$8"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$4", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$6"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$5", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$5"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$6", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$4$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$7", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$4"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$8", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$7"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$9", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$8"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$7", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$9"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$8", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$8"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$9", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$7", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$7$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$8", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1$macrocall$7"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$23", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$5"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$10", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$22"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$11", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$23"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$12", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$25"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$11"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$12"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$2", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$2"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$3", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$1$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$10", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$5", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$10"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$6", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$12"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$4", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$6"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$5", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$5"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$6", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$4$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$11", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$4"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$8", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$11"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$9", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$12"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$7", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$9"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$8", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$8"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$9", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$7", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$7$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$12", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1$macrocall$7"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$24", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$9"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$14", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$22"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$15", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$23"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$16", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$24"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$15"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$16"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$2", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$2"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$3", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$1$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$14", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$5", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$14"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$6", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$16"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$4", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$6"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$5", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$5"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$6", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$4$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$15", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$4"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$8", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$15"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$9", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$16"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$7", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1$macrocall$2", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$9"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1$macrocall$1", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1$macrocall$2"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$8", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1$macrocall$1"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1$macrocall$4", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$8"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1$macrocall$3", "symbols": [], "postprocess": () => []},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1$macrocall$3", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1$macrocall$4"], "postprocess": ([comb]) => comb},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$9", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1$macrocall$3"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$7", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$7$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$16", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1$macrocall$7"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$25", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1$macrocall$13"]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1$macrocall$21$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue$macrocall$1$subexpression$1", "symbols": ["colorCombinationValue$macrocall$6", "colorCombinationValue$macrocall$1$subexpression$1$macrocall$21"]},
    {"name": "colorCombinationValue$macrocall$1", "symbols": ["colorCombinationValue$macrocall$1$subexpression$1"], "postprocess": ([[[a], rest]]) => [a, ...rest.map(([c]) => c)]},
    {"name": "colorCombinationValue", "symbols": ["colorCombinationValue$macrocall$1"], "postprocess": ([comb]) => comb.map((c) => c.toLowerCase())},
    {"name": "dqstring$ebnf$1", "symbols": []},
    {"name": "dqstring$ebnf$1", "symbols": ["dqstring$ebnf$1", "dstrchar"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "dqstring", "symbols": [{"literal":"\""}, "dqstring$ebnf$1", {"literal":"\""}], "postprocess": function(d) {return d[1].join(""); }},
    {"name": "sqstring$ebnf$1", "symbols": []},
    {"name": "sqstring$ebnf$1", "symbols": ["sqstring$ebnf$1", "sstrchar"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "sqstring", "symbols": [{"literal":"'"}, "sqstring$ebnf$1", {"literal":"'"}], "postprocess": function(d) {return d[1].join(""); }},
    {"name": "btstring$ebnf$1", "symbols": []},
    {"name": "btstring$ebnf$1", "symbols": ["btstring$ebnf$1", /[^`]/], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "btstring", "symbols": [{"literal":"`"}, "btstring$ebnf$1", {"literal":"`"}], "postprocess": function(d) {return d[1].join(""); }},
    {"name": "dstrchar", "symbols": [/[^\\"\n]/], "postprocess": id},
    {"name": "dstrchar", "symbols": [{"literal":"\\"}, "strescape"], "postprocess": 
        function(d) {
            return JSON.parse("\""+d.join("")+"\"");
        }
        },
    {"name": "sstrchar", "symbols": [/[^\\'\n]/], "postprocess": id},
    {"name": "sstrchar", "symbols": [{"literal":"\\"}, "strescape"], "postprocess": function(d) { return JSON.parse("\""+d.join("")+"\""); }},
    {"name": "sstrchar$string$1", "symbols": [{"literal":"\\"}, {"literal":"'"}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "sstrchar", "symbols": ["sstrchar$string$1"], "postprocess": function(d) {return "'"; }},
    {"name": "strescape", "symbols": [/["\\/bfnrt]/], "postprocess": id},
    {"name": "strescape", "symbols": [{"literal":"u"}, /[a-fA-F0-9]/, /[a-fA-F0-9]/, /[a-fA-F0-9]/, /[a-fA-F0-9]/], "postprocess": 
        function(d) {
            return d.join("");
        }
        },
    {"name": "stringSetElementOpValue", "symbols": [{"literal":":"}, "stringValue"], "postprocess": ([op, value]) => setElementOperation(op, value)},
    {"name": "stringSetElementOpValue$subexpression$1", "symbols": [{"literal":"="}]},
    {"name": "stringSetElementOpValue$subexpression$1$string$1", "symbols": [{"literal":"!"}, {"literal":"="}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "stringSetElementOpValue$subexpression$1", "symbols": ["stringSetElementOpValue$subexpression$1$string$1"]},
    {"name": "stringSetElementOpValue$subexpression$1$string$2", "symbols": [{"literal":"<"}, {"literal":">"}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "stringSetElementOpValue$subexpression$1", "symbols": ["stringSetElementOpValue$subexpression$1$string$2"]},
    {"name": "stringSetElementOpValue$subexpression$1", "symbols": [{"literal":"<"}]},
    {"name": "stringSetElementOpValue$subexpression$1$string$3", "symbols": [{"literal":"<"}, {"literal":"="}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "stringSetElementOpValue$subexpression$1", "symbols": ["stringSetElementOpValue$subexpression$1$string$3"]},
    {"name": "stringSetElementOpValue$subexpression$1", "symbols": [{"literal":">"}]},
    {"name": "stringSetElementOpValue$subexpression$1$string$4", "symbols": [{"literal":">"}, {"literal":"="}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "stringSetElementOpValue$subexpression$1", "symbols": ["stringSetElementOpValue$subexpression$1$string$4"]},
    {"name": "stringSetElementOpValue", "symbols": ["stringSetElementOpValue$subexpression$1", "integerValue"], "postprocess": ([[op], value]) => setCountOperation(op, value)},
    {"name": "stringOpValue", "symbols": ["equalityOperator", "stringValue"], "postprocess": ([op, value]) => stringOperation(op, value)},
    {"name": "stringContainOpValue", "symbols": ["equalityOperator", "stringValue"], "postprocess": ([op, value]) => stringContainOperation(op, value)},
    {"name": "stringExactOpValue", "symbols": ["equalityOperator", "stringValue"], "postprocess": ([op, value]) => equalityOperation(op, value)},
    {"name": "nameStringOpValue", "symbols": ["equalityOperator", "stringValue"], "postprocess": ([op, value]) => nameStringOperation(op, value)},
    {"name": "stringValue$subexpression$1", "symbols": ["noQuoteStringValue"]},
    {"name": "stringValue$subexpression$1", "symbols": ["dqstring"]},
    {"name": "stringValue$subexpression$1", "symbols": ["sqstring"]},
    {"name": "stringValue", "symbols": ["stringValue$subexpression$1"], "postprocess": ([[value]]) => value.toLowerCase()},
    {"name": "noQuoteStringValue$subexpression$1$subexpression$1", "symbols": [/[aA]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "noQuoteStringValue$subexpression$1", "symbols": ["noQuoteStringValue$subexpression$1$subexpression$1"]},
    {"name": "noQuoteStringValue$subexpression$1$subexpression$2", "symbols": [/[aA]/, /[nN]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "noQuoteStringValue$subexpression$1", "symbols": ["noQuoteStringValue$subexpression$1$subexpression$2"]},
    {"name": "noQuoteStringValue$subexpression$1$subexpression$3", "symbols": [/[oO]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "noQuoteStringValue$subexpression$1", "symbols": ["noQuoteStringValue$subexpression$1$subexpression$3"]},
    {"name": "noQuoteStringValue", "symbols": ["noQuoteStringValue$subexpression$1"], "postprocess": ([[value]]) => value.toLowerCase()},
    {"name": "noQuoteStringValue$subexpression$2", "symbols": [/[^aAoO\- \t\n"'\\=<>:]/]},
    {"name": "noQuoteStringValue$subexpression$2$subexpression$1", "symbols": [/[aA]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "noQuoteStringValue$subexpression$2", "symbols": ["noQuoteStringValue$subexpression$2$subexpression$1", /[^nN \t\n"'\\=<>:]/]},
    {"name": "noQuoteStringValue$subexpression$2$subexpression$2", "symbols": [/[aA]/, /[nN]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "noQuoteStringValue$subexpression$2", "symbols": ["noQuoteStringValue$subexpression$2$subexpression$2", /[^dD \t\n"'\\=<>:]/]},
    {"name": "noQuoteStringValue$subexpression$2$subexpression$3", "symbols": [/[aA]/, /[nN]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "noQuoteStringValue$subexpression$2", "symbols": ["noQuoteStringValue$subexpression$2$subexpression$3", /[^ \t\n"'\\=<>:]/]},
    {"name": "noQuoteStringValue$subexpression$2$subexpression$4", "symbols": [/[oO]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "noQuoteStringValue$subexpression$2", "symbols": ["noQuoteStringValue$subexpression$2$subexpression$4", /[^rR \t\n"'\\=<>:]/]},
    {"name": "noQuoteStringValue$subexpression$2$subexpression$5", "symbols": [/[oO]/, /[rR]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "noQuoteStringValue$subexpression$2", "symbols": ["noQuoteStringValue$subexpression$2$subexpression$5", /[^ \t\n"'\\=<>:]/]},
    {"name": "noQuoteStringValue$ebnf$1", "symbols": []},
    {"name": "noQuoteStringValue$ebnf$1", "symbols": ["noQuoteStringValue$ebnf$1", /[^ \t\n"'\\=<>:]/], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "noQuoteStringValue", "symbols": ["noQuoteStringValue$subexpression$2", "noQuoteStringValue$ebnf$1"], "postprocess": ([startChars, chars]) => startChars.concat(chars).join('').toLowerCase()},
    {"name": "manaCostOpValue", "symbols": ["equalityOperator", "manaCostValue"], "postprocess": ([op, value]) => manaCostOperation(op, value)},
    {"name": "manaCostValue$ebnf$1", "symbols": ["manaSymbol"]},
    {"name": "manaCostValue$ebnf$1", "symbols": ["manaCostValue$ebnf$1", "manaSymbol"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "manaCostValue", "symbols": ["manaCostValue$ebnf$1"], "postprocess": id},
    {"name": "manaSymbol", "symbols": ["innerManaSymbol"], "postprocess": id},
    {"name": "manaSymbol", "symbols": [{"literal":"{"}, "innerManaSymbol", {"literal":"}"}], "postprocess": ([, inner]) => inner},
    {"name": "manaSymbol", "symbols": [{"literal":"("}, "innerManaSymbol", {"literal":")"}], "postprocess": ([, inner]) => inner},
    {"name": "innerManaSymbol$ebnf$1", "symbols": [/[0-9]/]},
    {"name": "innerManaSymbol$ebnf$1", "symbols": ["innerManaSymbol$ebnf$1", /[0-9]/], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "innerManaSymbol", "symbols": ["innerManaSymbol$ebnf$1"], "postprocess": ([digits]) => [digits.join('')]},
    {"name": "innerManaSymbol$subexpression$1$subexpression$1", "symbols": [/[xX]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerManaSymbol$subexpression$1", "symbols": ["innerManaSymbol$subexpression$1$subexpression$1"]},
    {"name": "innerManaSymbol$subexpression$1$subexpression$2", "symbols": [/[yY]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerManaSymbol$subexpression$1", "symbols": ["innerManaSymbol$subexpression$1$subexpression$2"]},
    {"name": "innerManaSymbol$subexpression$1$subexpression$3", "symbols": [/[zZ]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerManaSymbol$subexpression$1", "symbols": ["innerManaSymbol$subexpression$1$subexpression$3"]},
    {"name": "innerManaSymbol$subexpression$1$subexpression$4", "symbols": [/[wW]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerManaSymbol$subexpression$1", "symbols": ["innerManaSymbol$subexpression$1$subexpression$4"]},
    {"name": "innerManaSymbol$subexpression$1$subexpression$5", "symbols": [/[uU]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerManaSymbol$subexpression$1", "symbols": ["innerManaSymbol$subexpression$1$subexpression$5"]},
    {"name": "innerManaSymbol$subexpression$1$subexpression$6", "symbols": [/[bB]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerManaSymbol$subexpression$1", "symbols": ["innerManaSymbol$subexpression$1$subexpression$6"]},
    {"name": "innerManaSymbol$subexpression$1$subexpression$7", "symbols": [/[rR]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerManaSymbol$subexpression$1", "symbols": ["innerManaSymbol$subexpression$1$subexpression$7"]},
    {"name": "innerManaSymbol$subexpression$1$subexpression$8", "symbols": [/[gG]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerManaSymbol$subexpression$1", "symbols": ["innerManaSymbol$subexpression$1$subexpression$8"]},
    {"name": "innerManaSymbol$subexpression$1$subexpression$9", "symbols": [/[sS]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerManaSymbol$subexpression$1", "symbols": ["innerManaSymbol$subexpression$1$subexpression$9"]},
    {"name": "innerManaSymbol$subexpression$1$subexpression$10", "symbols": [/[cC]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerManaSymbol$subexpression$1", "symbols": ["innerManaSymbol$subexpression$1$subexpression$10"]},
    {"name": "innerManaSymbol", "symbols": ["innerManaSymbol$subexpression$1"], "postprocess": ([[color]]) => [color.toLowerCase()]},
    {"name": "innerManaSymbol$subexpression$2$subexpression$1", "symbols": [{"literal":"2"}], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerManaSymbol$subexpression$2$subexpression$2", "symbols": [{"literal":"/"}]},
    {"name": "innerManaSymbol$subexpression$2$subexpression$2", "symbols": [{"literal":"-"}]},
    {"name": "innerManaSymbol$subexpression$2$subexpression$3$subexpression$1", "symbols": [/[wW]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerManaSymbol$subexpression$2$subexpression$3", "symbols": ["innerManaSymbol$subexpression$2$subexpression$3$subexpression$1"]},
    {"name": "innerManaSymbol$subexpression$2$subexpression$3$subexpression$2", "symbols": [/[uU]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerManaSymbol$subexpression$2$subexpression$3", "symbols": ["innerManaSymbol$subexpression$2$subexpression$3$subexpression$2"]},
    {"name": "innerManaSymbol$subexpression$2$subexpression$3$subexpression$3", "symbols": [/[bB]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerManaSymbol$subexpression$2$subexpression$3", "symbols": ["innerManaSymbol$subexpression$2$subexpression$3$subexpression$3"]},
    {"name": "innerManaSymbol$subexpression$2$subexpression$3$subexpression$4", "symbols": [/[rR]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerManaSymbol$subexpression$2$subexpression$3", "symbols": ["innerManaSymbol$subexpression$2$subexpression$3$subexpression$4"]},
    {"name": "innerManaSymbol$subexpression$2$subexpression$3$subexpression$5", "symbols": [/[gG]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerManaSymbol$subexpression$2$subexpression$3", "symbols": ["innerManaSymbol$subexpression$2$subexpression$3$subexpression$5"]},
    {"name": "innerManaSymbol$subexpression$2", "symbols": ["innerManaSymbol$subexpression$2$subexpression$1", "innerManaSymbol$subexpression$2$subexpression$2", "innerManaSymbol$subexpression$2$subexpression$3"]},
    {"name": "innerManaSymbol$subexpression$2$subexpression$4", "symbols": [/[pP]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerManaSymbol$subexpression$2$subexpression$5", "symbols": [{"literal":"/"}]},
    {"name": "innerManaSymbol$subexpression$2$subexpression$5", "symbols": [{"literal":"-"}]},
    {"name": "innerManaSymbol$subexpression$2$subexpression$6$subexpression$1", "symbols": [/[wW]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerManaSymbol$subexpression$2$subexpression$6", "symbols": ["innerManaSymbol$subexpression$2$subexpression$6$subexpression$1"]},
    {"name": "innerManaSymbol$subexpression$2$subexpression$6$subexpression$2", "symbols": [/[uU]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerManaSymbol$subexpression$2$subexpression$6", "symbols": ["innerManaSymbol$subexpression$2$subexpression$6$subexpression$2"]},
    {"name": "innerManaSymbol$subexpression$2$subexpression$6$subexpression$3", "symbols": [/[bB]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerManaSymbol$subexpression$2$subexpression$6", "symbols": ["innerManaSymbol$subexpression$2$subexpression$6$subexpression$3"]},
    {"name": "innerManaSymbol$subexpression$2$subexpression$6$subexpression$4", "symbols": [/[rR]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerManaSymbol$subexpression$2$subexpression$6", "symbols": ["innerManaSymbol$subexpression$2$subexpression$6$subexpression$4"]},
    {"name": "innerManaSymbol$subexpression$2$subexpression$6$subexpression$5", "symbols": [/[gG]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerManaSymbol$subexpression$2$subexpression$6", "symbols": ["innerManaSymbol$subexpression$2$subexpression$6$subexpression$5"]},
    {"name": "innerManaSymbol$subexpression$2", "symbols": ["innerManaSymbol$subexpression$2$subexpression$4", "innerManaSymbol$subexpression$2$subexpression$5", "innerManaSymbol$subexpression$2$subexpression$6"]},
    {"name": "innerManaSymbol$subexpression$2$subexpression$7", "symbols": [/[wW]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerManaSymbol$subexpression$2$subexpression$8", "symbols": [{"literal":"/"}]},
    {"name": "innerManaSymbol$subexpression$2$subexpression$8", "symbols": [{"literal":"-"}]},
    {"name": "innerManaSymbol$subexpression$2$subexpression$9$subexpression$1", "symbols": [{"literal":"2"}], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerManaSymbol$subexpression$2$subexpression$9", "symbols": ["innerManaSymbol$subexpression$2$subexpression$9$subexpression$1"]},
    {"name": "innerManaSymbol$subexpression$2$subexpression$9$subexpression$2", "symbols": [/[pP]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerManaSymbol$subexpression$2$subexpression$9", "symbols": ["innerManaSymbol$subexpression$2$subexpression$9$subexpression$2"]},
    {"name": "innerManaSymbol$subexpression$2$subexpression$9$subexpression$3", "symbols": [/[uU]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerManaSymbol$subexpression$2$subexpression$9", "symbols": ["innerManaSymbol$subexpression$2$subexpression$9$subexpression$3"]},
    {"name": "innerManaSymbol$subexpression$2$subexpression$9$subexpression$4", "symbols": [/[bB]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerManaSymbol$subexpression$2$subexpression$9", "symbols": ["innerManaSymbol$subexpression$2$subexpression$9$subexpression$4"]},
    {"name": "innerManaSymbol$subexpression$2$subexpression$9$subexpression$5", "symbols": [/[rR]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerManaSymbol$subexpression$2$subexpression$9", "symbols": ["innerManaSymbol$subexpression$2$subexpression$9$subexpression$5"]},
    {"name": "innerManaSymbol$subexpression$2$subexpression$9$subexpression$6", "symbols": [/[gG]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerManaSymbol$subexpression$2$subexpression$9", "symbols": ["innerManaSymbol$subexpression$2$subexpression$9$subexpression$6"]},
    {"name": "innerManaSymbol$subexpression$2", "symbols": ["innerManaSymbol$subexpression$2$subexpression$7", "innerManaSymbol$subexpression$2$subexpression$8", "innerManaSymbol$subexpression$2$subexpression$9"]},
    {"name": "innerManaSymbol$subexpression$2$subexpression$10", "symbols": [/[uU]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerManaSymbol$subexpression$2$subexpression$11", "symbols": [{"literal":"/"}]},
    {"name": "innerManaSymbol$subexpression$2$subexpression$11", "symbols": [{"literal":"-"}]},
    {"name": "innerManaSymbol$subexpression$2$subexpression$12$subexpression$1", "symbols": [{"literal":"2"}], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerManaSymbol$subexpression$2$subexpression$12", "symbols": ["innerManaSymbol$subexpression$2$subexpression$12$subexpression$1"]},
    {"name": "innerManaSymbol$subexpression$2$subexpression$12$subexpression$2", "symbols": [/[pP]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerManaSymbol$subexpression$2$subexpression$12", "symbols": ["innerManaSymbol$subexpression$2$subexpression$12$subexpression$2"]},
    {"name": "innerManaSymbol$subexpression$2$subexpression$12$subexpression$3", "symbols": [/[wW]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerManaSymbol$subexpression$2$subexpression$12", "symbols": ["innerManaSymbol$subexpression$2$subexpression$12$subexpression$3"]},
    {"name": "innerManaSymbol$subexpression$2$subexpression$12$subexpression$4", "symbols": [/[bB]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerManaSymbol$subexpression$2$subexpression$12", "symbols": ["innerManaSymbol$subexpression$2$subexpression$12$subexpression$4"]},
    {"name": "innerManaSymbol$subexpression$2$subexpression$12$subexpression$5", "symbols": [/[rR]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerManaSymbol$subexpression$2$subexpression$12", "symbols": ["innerManaSymbol$subexpression$2$subexpression$12$subexpression$5"]},
    {"name": "innerManaSymbol$subexpression$2$subexpression$12$subexpression$6", "symbols": [/[gG]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerManaSymbol$subexpression$2$subexpression$12", "symbols": ["innerManaSymbol$subexpression$2$subexpression$12$subexpression$6"]},
    {"name": "innerManaSymbol$subexpression$2", "symbols": ["innerManaSymbol$subexpression$2$subexpression$10", "innerManaSymbol$subexpression$2$subexpression$11", "innerManaSymbol$subexpression$2$subexpression$12"]},
    {"name": "innerManaSymbol$subexpression$2$subexpression$13", "symbols": [/[bB]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerManaSymbol$subexpression$2$subexpression$14", "symbols": [{"literal":"/"}]},
    {"name": "innerManaSymbol$subexpression$2$subexpression$14", "symbols": [{"literal":"-"}]},
    {"name": "innerManaSymbol$subexpression$2$subexpression$15$subexpression$1", "symbols": [{"literal":"2"}], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerManaSymbol$subexpression$2$subexpression$15", "symbols": ["innerManaSymbol$subexpression$2$subexpression$15$subexpression$1"]},
    {"name": "innerManaSymbol$subexpression$2$subexpression$15$subexpression$2", "symbols": [/[pP]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerManaSymbol$subexpression$2$subexpression$15", "symbols": ["innerManaSymbol$subexpression$2$subexpression$15$subexpression$2"]},
    {"name": "innerManaSymbol$subexpression$2$subexpression$15$subexpression$3", "symbols": [/[wW]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerManaSymbol$subexpression$2$subexpression$15", "symbols": ["innerManaSymbol$subexpression$2$subexpression$15$subexpression$3"]},
    {"name": "innerManaSymbol$subexpression$2$subexpression$15$subexpression$4", "symbols": [/[uU]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerManaSymbol$subexpression$2$subexpression$15", "symbols": ["innerManaSymbol$subexpression$2$subexpression$15$subexpression$4"]},
    {"name": "innerManaSymbol$subexpression$2$subexpression$15$subexpression$5", "symbols": [/[rR]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerManaSymbol$subexpression$2$subexpression$15", "symbols": ["innerManaSymbol$subexpression$2$subexpression$15$subexpression$5"]},
    {"name": "innerManaSymbol$subexpression$2$subexpression$15$subexpression$6", "symbols": [/[gG]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerManaSymbol$subexpression$2$subexpression$15", "symbols": ["innerManaSymbol$subexpression$2$subexpression$15$subexpression$6"]},
    {"name": "innerManaSymbol$subexpression$2", "symbols": ["innerManaSymbol$subexpression$2$subexpression$13", "innerManaSymbol$subexpression$2$subexpression$14", "innerManaSymbol$subexpression$2$subexpression$15"]},
    {"name": "innerManaSymbol$subexpression$2$subexpression$16", "symbols": [/[rR]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerManaSymbol$subexpression$2$subexpression$17", "symbols": [{"literal":"/"}]},
    {"name": "innerManaSymbol$subexpression$2$subexpression$17", "symbols": [{"literal":"-"}]},
    {"name": "innerManaSymbol$subexpression$2$subexpression$18$subexpression$1", "symbols": [{"literal":"2"}], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerManaSymbol$subexpression$2$subexpression$18", "symbols": ["innerManaSymbol$subexpression$2$subexpression$18$subexpression$1"]},
    {"name": "innerManaSymbol$subexpression$2$subexpression$18$subexpression$2", "symbols": [/[pP]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerManaSymbol$subexpression$2$subexpression$18", "symbols": ["innerManaSymbol$subexpression$2$subexpression$18$subexpression$2"]},
    {"name": "innerManaSymbol$subexpression$2$subexpression$18$subexpression$3", "symbols": [/[wW]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerManaSymbol$subexpression$2$subexpression$18", "symbols": ["innerManaSymbol$subexpression$2$subexpression$18$subexpression$3"]},
    {"name": "innerManaSymbol$subexpression$2$subexpression$18$subexpression$4", "symbols": [/[uU]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerManaSymbol$subexpression$2$subexpression$18", "symbols": ["innerManaSymbol$subexpression$2$subexpression$18$subexpression$4"]},
    {"name": "innerManaSymbol$subexpression$2$subexpression$18$subexpression$5", "symbols": [/[bB]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerManaSymbol$subexpression$2$subexpression$18", "symbols": ["innerManaSymbol$subexpression$2$subexpression$18$subexpression$5"]},
    {"name": "innerManaSymbol$subexpression$2$subexpression$18$subexpression$6", "symbols": [/[gG]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerManaSymbol$subexpression$2$subexpression$18", "symbols": ["innerManaSymbol$subexpression$2$subexpression$18$subexpression$6"]},
    {"name": "innerManaSymbol$subexpression$2", "symbols": ["innerManaSymbol$subexpression$2$subexpression$16", "innerManaSymbol$subexpression$2$subexpression$17", "innerManaSymbol$subexpression$2$subexpression$18"]},
    {"name": "innerManaSymbol$subexpression$2$subexpression$19", "symbols": [/[gG]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerManaSymbol$subexpression$2$subexpression$20", "symbols": [{"literal":"/"}]},
    {"name": "innerManaSymbol$subexpression$2$subexpression$20", "symbols": [{"literal":"-"}]},
    {"name": "innerManaSymbol$subexpression$2$subexpression$21$subexpression$1", "symbols": [{"literal":"2"}], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerManaSymbol$subexpression$2$subexpression$21", "symbols": ["innerManaSymbol$subexpression$2$subexpression$21$subexpression$1"]},
    {"name": "innerManaSymbol$subexpression$2$subexpression$21$subexpression$2", "symbols": [/[pP]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerManaSymbol$subexpression$2$subexpression$21", "symbols": ["innerManaSymbol$subexpression$2$subexpression$21$subexpression$2"]},
    {"name": "innerManaSymbol$subexpression$2$subexpression$21$subexpression$3", "symbols": [/[wW]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerManaSymbol$subexpression$2$subexpression$21", "symbols": ["innerManaSymbol$subexpression$2$subexpression$21$subexpression$3"]},
    {"name": "innerManaSymbol$subexpression$2$subexpression$21$subexpression$4", "symbols": [/[uU]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerManaSymbol$subexpression$2$subexpression$21", "symbols": ["innerManaSymbol$subexpression$2$subexpression$21$subexpression$4"]},
    {"name": "innerManaSymbol$subexpression$2$subexpression$21$subexpression$5", "symbols": [/[bB]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerManaSymbol$subexpression$2$subexpression$21", "symbols": ["innerManaSymbol$subexpression$2$subexpression$21$subexpression$5"]},
    {"name": "innerManaSymbol$subexpression$2$subexpression$21$subexpression$6", "symbols": [/[rR]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerManaSymbol$subexpression$2$subexpression$21", "symbols": ["innerManaSymbol$subexpression$2$subexpression$21$subexpression$6"]},
    {"name": "innerManaSymbol$subexpression$2", "symbols": ["innerManaSymbol$subexpression$2$subexpression$19", "innerManaSymbol$subexpression$2$subexpression$20", "innerManaSymbol$subexpression$2$subexpression$21"]},
    {"name": "innerManaSymbol", "symbols": ["innerManaSymbol$subexpression$2"], "postprocess": ([[color, , [secondColor]]]) => [color, secondColor]},
    {"name": "castableCostOpValue$subexpression$1", "symbols": [{"literal":"="}]},
    {"name": "castableCostOpValue$subexpression$1", "symbols": [{"literal":":"}]},
    {"name": "castableCostOpValue$subexpression$1$string$1", "symbols": [{"literal":"<"}, {"literal":">"}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "castableCostOpValue$subexpression$1", "symbols": ["castableCostOpValue$subexpression$1$string$1"]},
    {"name": "castableCostOpValue$subexpression$1$string$2", "symbols": [{"literal":"!"}, {"literal":"="}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "castableCostOpValue$subexpression$1", "symbols": ["castableCostOpValue$subexpression$1$string$2"]},
    {"name": "castableCostOpValue$subexpression$1$string$3", "symbols": [{"literal":"<"}, {"literal":"="}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "castableCostOpValue$subexpression$1", "symbols": ["castableCostOpValue$subexpression$1$string$3"]},
    {"name": "castableCostOpValue$subexpression$1", "symbols": [{"literal":">"}]},
    {"name": "castableCostOpValue", "symbols": ["castableCostOpValue$subexpression$1", "basicCostValue"], "postprocess": ([op, value]) => castableCostOperation(op, value)},
    {"name": "basicCostValue$ebnf$1", "symbols": ["basicManaSymbol"]},
    {"name": "basicCostValue$ebnf$1", "symbols": ["basicCostValue$ebnf$1", "basicManaSymbol"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "basicCostValue", "symbols": ["basicCostValue$ebnf$1"], "postprocess": id},
    {"name": "basicManaSymbol", "symbols": ["innerBasicManaSymbol"], "postprocess": id},
    {"name": "basicManaSymbol", "symbols": [{"literal":"{"}, "innerBasicManaSymbol", {"literal":"}"}], "postprocess": ([, inner]) => inner},
    {"name": "basicManaSymbol", "symbols": [{"literal":"("}, "innerBasicManaSymbol", {"literal":")"}], "postprocess": ([, inner]) => inner},
    {"name": "innerBasicManaSymbol$ebnf$1", "symbols": [/[0-9]/]},
    {"name": "innerBasicManaSymbol$ebnf$1", "symbols": ["innerBasicManaSymbol$ebnf$1", /[0-9]/], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "innerBasicManaSymbol", "symbols": ["innerBasicManaSymbol$ebnf$1"], "postprocess": ([digits]) => parseInt(digits.join(''))},
    {"name": "innerBasicManaSymbol$subexpression$1$subexpression$1", "symbols": [/[wW]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerBasicManaSymbol$subexpression$1", "symbols": ["innerBasicManaSymbol$subexpression$1$subexpression$1"]},
    {"name": "innerBasicManaSymbol$subexpression$1$subexpression$2", "symbols": [/[uU]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerBasicManaSymbol$subexpression$1", "symbols": ["innerBasicManaSymbol$subexpression$1$subexpression$2"]},
    {"name": "innerBasicManaSymbol$subexpression$1$subexpression$3", "symbols": [/[bB]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerBasicManaSymbol$subexpression$1", "symbols": ["innerBasicManaSymbol$subexpression$1$subexpression$3"]},
    {"name": "innerBasicManaSymbol$subexpression$1$subexpression$4", "symbols": [/[rR]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerBasicManaSymbol$subexpression$1", "symbols": ["innerBasicManaSymbol$subexpression$1$subexpression$4"]},
    {"name": "innerBasicManaSymbol$subexpression$1$subexpression$5", "symbols": [/[gG]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerBasicManaSymbol$subexpression$1", "symbols": ["innerBasicManaSymbol$subexpression$1$subexpression$5"]},
    {"name": "innerBasicManaSymbol$subexpression$1$subexpression$6", "symbols": [/[sS]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerBasicManaSymbol$subexpression$1", "symbols": ["innerBasicManaSymbol$subexpression$1$subexpression$6"]},
    {"name": "innerBasicManaSymbol$subexpression$1$subexpression$7", "symbols": [/[cC]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "innerBasicManaSymbol$subexpression$1", "symbols": ["innerBasicManaSymbol$subexpression$1$subexpression$7"]},
    {"name": "innerBasicManaSymbol", "symbols": ["innerBasicManaSymbol$subexpression$1"], "postprocess": ([[color]]) => color.toLowerCase()},
    {"name": "devotionOpValue", "symbols": ["anyOperator", "devotionValue"], "postprocess": ([op, [symbol, length]]) => devotionOperation(op, symbol, length)},
    {"name": "devotionValue$subexpression$1$ebnf$1$subexpression$1", "symbols": [/[wW]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "devotionValue$subexpression$1$ebnf$1", "symbols": ["devotionValue$subexpression$1$ebnf$1$subexpression$1"]},
    {"name": "devotionValue$subexpression$1$ebnf$1$subexpression$2", "symbols": [/[wW]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "devotionValue$subexpression$1$ebnf$1", "symbols": ["devotionValue$subexpression$1$ebnf$1", "devotionValue$subexpression$1$ebnf$1$subexpression$2"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "devotionValue$subexpression$1", "symbols": ["devotionValue$subexpression$1$ebnf$1"]},
    {"name": "devotionValue$subexpression$1$ebnf$2$subexpression$1", "symbols": [/[uU]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "devotionValue$subexpression$1$ebnf$2", "symbols": ["devotionValue$subexpression$1$ebnf$2$subexpression$1"]},
    {"name": "devotionValue$subexpression$1$ebnf$2$subexpression$2", "symbols": [/[uU]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "devotionValue$subexpression$1$ebnf$2", "symbols": ["devotionValue$subexpression$1$ebnf$2", "devotionValue$subexpression$1$ebnf$2$subexpression$2"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "devotionValue$subexpression$1", "symbols": ["devotionValue$subexpression$1$ebnf$2"]},
    {"name": "devotionValue$subexpression$1$ebnf$3$subexpression$1", "symbols": [/[bB]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "devotionValue$subexpression$1$ebnf$3", "symbols": ["devotionValue$subexpression$1$ebnf$3$subexpression$1"]},
    {"name": "devotionValue$subexpression$1$ebnf$3$subexpression$2", "symbols": [/[bB]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "devotionValue$subexpression$1$ebnf$3", "symbols": ["devotionValue$subexpression$1$ebnf$3", "devotionValue$subexpression$1$ebnf$3$subexpression$2"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "devotionValue$subexpression$1", "symbols": ["devotionValue$subexpression$1$ebnf$3"]},
    {"name": "devotionValue$subexpression$1$ebnf$4$subexpression$1", "symbols": [/[rR]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "devotionValue$subexpression$1$ebnf$4", "symbols": ["devotionValue$subexpression$1$ebnf$4$subexpression$1"]},
    {"name": "devotionValue$subexpression$1$ebnf$4$subexpression$2", "symbols": [/[rR]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "devotionValue$subexpression$1$ebnf$4", "symbols": ["devotionValue$subexpression$1$ebnf$4", "devotionValue$subexpression$1$ebnf$4$subexpression$2"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "devotionValue$subexpression$1", "symbols": ["devotionValue$subexpression$1$ebnf$4"]},
    {"name": "devotionValue$subexpression$1$ebnf$5$subexpression$1", "symbols": [/[gG]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "devotionValue$subexpression$1$ebnf$5", "symbols": ["devotionValue$subexpression$1$ebnf$5$subexpression$1"]},
    {"name": "devotionValue$subexpression$1$ebnf$5$subexpression$2", "symbols": [/[gG]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "devotionValue$subexpression$1$ebnf$5", "symbols": ["devotionValue$subexpression$1$ebnf$5", "devotionValue$subexpression$1$ebnf$5$subexpression$2"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "devotionValue$subexpression$1", "symbols": ["devotionValue$subexpression$1$ebnf$5"]},
    {"name": "devotionValue", "symbols": ["devotionValue$subexpression$1"], "postprocess": ([[sequence]]) => [sequence[0], sequence.length]},
    {"name": "anyOperator", "symbols": [{"literal":":"}]},
    {"name": "anyOperator", "symbols": [{"literal":"="}]},
    {"name": "anyOperator$string$1", "symbols": [{"literal":"!"}, {"literal":"="}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "anyOperator", "symbols": ["anyOperator$string$1"]},
    {"name": "anyOperator$string$2", "symbols": [{"literal":"<"}, {"literal":">"}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "anyOperator", "symbols": ["anyOperator$string$2"]},
    {"name": "anyOperator", "symbols": [{"literal":"<"}]},
    {"name": "anyOperator$string$3", "symbols": [{"literal":"<"}, {"literal":"="}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "anyOperator", "symbols": ["anyOperator$string$3"]},
    {"name": "anyOperator", "symbols": [{"literal":">"}]},
    {"name": "anyOperator$string$4", "symbols": [{"literal":">"}, {"literal":"="}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "anyOperator", "symbols": ["anyOperator$string$4"], "postprocess": id},
    {"name": "equalityOperator", "symbols": [{"literal":":"}]},
    {"name": "equalityOperator", "symbols": [{"literal":"="}]},
    {"name": "equalityOperator$string$1", "symbols": [{"literal":"!"}, {"literal":"="}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "equalityOperator", "symbols": ["equalityOperator$string$1"]},
    {"name": "equalityOperator$string$2", "symbols": [{"literal":"<"}, {"literal":">"}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "equalityOperator", "symbols": ["equalityOperator$string$2"], "postprocess": id},
    {"name": "start", "symbols": ["filterStart"], "postprocess": id},
    {"name": "connector$subexpression$1", "symbols": []},
    {"name": "connector$subexpression$1$subexpression$1", "symbols": [/[aA]/, /[nN]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "connector$subexpression$1", "symbols": ["connector$subexpression$1$subexpression$1", "__"]},
    {"name": "connector", "symbols": ["connector$subexpression$1"], "postprocess": () => (clause1, clause2) => (card) => clause1(card) && clause2(card)},
    {"name": "connector$subexpression$2", "symbols": [/[oO]/, /[rR]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "connector", "symbols": ["connector$subexpression$2", "__"], "postprocess": () => (clause1, clause2) => (card) => clause1(card) || clause2(card)},
    {"name": "condition$subexpression$1", "symbols": ["cmcCondition"]},
    {"name": "condition$subexpression$1", "symbols": ["colorCondition"]},
    {"name": "condition$subexpression$1", "symbols": ["colorIdentityCondition"]},
    {"name": "condition$subexpression$1", "symbols": ["typeCondition"]},
    {"name": "condition$subexpression$1", "symbols": ["oracleCondition"]},
    {"name": "condition$subexpression$1", "symbols": ["setCondition"]},
    {"name": "condition$subexpression$1", "symbols": ["powerCondition"]},
    {"name": "condition$subexpression$1", "symbols": ["toughnessCondition"]},
    {"name": "condition$subexpression$1", "symbols": ["ptSumCondition"]},
    {"name": "condition$subexpression$1", "symbols": ["tagCondition"]},
    {"name": "condition$subexpression$1", "symbols": ["finishCondition"]},
    {"name": "condition$subexpression$1", "symbols": ["priceCondition"]},
    {"name": "condition$subexpression$1", "symbols": ["normalPriceCondition"]},
    {"name": "condition$subexpression$1", "symbols": ["foilPriceCondition"]},
    {"name": "condition$subexpression$1", "symbols": ["eurPriceCondition"]},
    {"name": "condition$subexpression$1", "symbols": ["tixPriceCondition"]},
    {"name": "condition$subexpression$1", "symbols": ["statusCondition"]},
    {"name": "condition$subexpression$1", "symbols": ["rarityCondition"]},
    {"name": "condition$subexpression$1", "symbols": ["loyaltyCondition"]},
    {"name": "condition$subexpression$1", "symbols": ["artistCondition"]},
    {"name": "condition$subexpression$1", "symbols": ["isCondition"]},
    {"name": "condition$subexpression$1", "symbols": ["notCondition"]},
    {"name": "condition$subexpression$1", "symbols": ["eloCondition"]},
    {"name": "condition$subexpression$1", "symbols": ["popularityCondition"]},
    {"name": "condition$subexpression$1", "symbols": ["cubeCountCondition"]},
    {"name": "condition$subexpression$1", "symbols": ["pickCountCondition"]},
    {"name": "condition$subexpression$1", "symbols": ["nameCondition"]},
    {"name": "condition$subexpression$1", "symbols": ["manaCostCondition"]},
    {"name": "condition$subexpression$1", "symbols": ["castableCostCondition"]},
    {"name": "condition$subexpression$1", "symbols": ["devotionCondition"]},
    {"name": "condition$subexpression$1", "symbols": ["legalityCondition"]},
    {"name": "condition$subexpression$1", "symbols": ["bannedCondition"]},
    {"name": "condition$subexpression$1", "symbols": ["restrictedCondition"]},
    {"name": "condition$subexpression$1", "symbols": ["layoutCondition"]},
    {"name": "condition$subexpression$1", "symbols": ["collectorNumberCondition"]},
    {"name": "condition", "symbols": ["condition$subexpression$1"], "postprocess": ([[condition]]) => condition},
    {"name": "cmcCondition$subexpression$1$subexpression$1", "symbols": [/[mM]/, /[vV]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "cmcCondition$subexpression$1", "symbols": ["cmcCondition$subexpression$1$subexpression$1"]},
    {"name": "cmcCondition$subexpression$1$subexpression$2", "symbols": [/[cC]/, /[mM]/, /[cC]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "cmcCondition$subexpression$1", "symbols": ["cmcCondition$subexpression$1$subexpression$2"]},
    {"name": "cmcCondition", "symbols": ["cmcCondition$subexpression$1", "integerOpValue"], "postprocess": ([, valuePred]) => genericCondition('cmc', cardCmc, valuePred)},
    {"name": "colorCondition$subexpression$1$subexpression$1", "symbols": [/[cC]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "colorCondition$subexpression$1", "symbols": ["colorCondition$subexpression$1$subexpression$1"]},
    {"name": "colorCondition$subexpression$1$subexpression$2", "symbols": [/[cC]/, /[oO]/, /[lL]/, /[oO]/, /[rR]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "colorCondition$subexpression$1", "symbols": ["colorCondition$subexpression$1$subexpression$2"]},
    {"name": "colorCondition$subexpression$1$subexpression$3", "symbols": [/[cC]/, /[oO]/, /[lL]/, /[oO]/, /[rR]/, /[sS]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "colorCondition$subexpression$1", "symbols": ["colorCondition$subexpression$1$subexpression$3"]},
    {"name": "colorCondition", "symbols": ["colorCondition$subexpression$1", "colorCombinationOpValue"], "postprocess": ([, valuePred]) => genericCondition('colors', cardColors, valuePred)},
    {"name": "colorIdentityCondition$subexpression$1$subexpression$1", "symbols": [/[cC]/, /[iI]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "colorIdentityCondition$subexpression$1", "symbols": ["colorIdentityCondition$subexpression$1$subexpression$1"]},
    {"name": "colorIdentityCondition$subexpression$1$subexpression$2", "symbols": [/[iI]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "colorIdentityCondition$subexpression$1", "symbols": ["colorIdentityCondition$subexpression$1$subexpression$2"]},
    {"name": "colorIdentityCondition$subexpression$1$subexpression$3", "symbols": [/[iI]/, /[dD]/, /[eE]/, /[nN]/, /[tT]/, /[iI]/, /[tT]/, /[yY]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "colorIdentityCondition$subexpression$1", "symbols": ["colorIdentityCondition$subexpression$1$subexpression$3"]},
    {"name": "colorIdentityCondition$subexpression$1$string$1", "symbols": [{"literal":"c"}, {"literal":"o"}, {"literal":"l"}, {"literal":"o"}, {"literal":"r"}, {"literal":"i"}, {"literal":"d"}, {"literal":"e"}, {"literal":"n"}, {"literal":"t"}, {"literal":"i"}, {"literal":"t"}, {"literal":"y"}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "colorIdentityCondition$subexpression$1", "symbols": ["colorIdentityCondition$subexpression$1$string$1"]},
    {"name": "colorIdentityCondition$subexpression$1$subexpression$4", "symbols": [/[cC]/, /[oO]/, /[lL]/, /[oO]/, /[rR]/, {"literal":"_"}, /[iI]/, /[dD]/, /[eE]/, /[nN]/, /[tT]/, /[iI]/, /[tT]/, /[yY]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "colorIdentityCondition$subexpression$1", "symbols": ["colorIdentityCondition$subexpression$1$subexpression$4"]},
    {"name": "colorIdentityCondition", "symbols": ["colorIdentityCondition$subexpression$1", "colorIdentityOpValue"], "postprocess": ([, valuePred]) => genericCondition('color_identity',cardColorIdentity, valuePred)},
    {"name": "typeCondition$subexpression$1$subexpression$1", "symbols": [/[tT]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "typeCondition$subexpression$1", "symbols": ["typeCondition$subexpression$1$subexpression$1"]},
    {"name": "typeCondition$subexpression$1$subexpression$2", "symbols": [/[tT]/, /[yY]/, /[pP]/, /[eE]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "typeCondition$subexpression$1", "symbols": ["typeCondition$subexpression$1$subexpression$2"]},
    {"name": "typeCondition$subexpression$1$subexpression$3", "symbols": [/[tT]/, /[yY]/, /[pP]/, /[eE]/, {"literal":"_"}, /[lL]/, /[iI]/, /[nN]/, /[eE]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "typeCondition$subexpression$1", "symbols": ["typeCondition$subexpression$1$subexpression$3"]},
    {"name": "typeCondition$subexpression$1$subexpression$4", "symbols": [/[tT]/, /[yY]/, /[pP]/, /[eE]/, /[lL]/, /[iI]/, /[nN]/, /[eE]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "typeCondition$subexpression$1", "symbols": ["typeCondition$subexpression$1$subexpression$4"]},
    {"name": "typeCondition", "symbols": ["typeCondition$subexpression$1", "stringContainOpValue"], "postprocess": ([, valuePred]) => genericCondition('type_line', cardType, valuePred)},
    {"name": "oracleCondition$subexpression$1$subexpression$1", "symbols": [/[oO]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "oracleCondition$subexpression$1", "symbols": ["oracleCondition$subexpression$1$subexpression$1"]},
    {"name": "oracleCondition$subexpression$1$subexpression$2", "symbols": [/[oO]/, /[rR]/, /[aA]/, /[cC]/, /[lL]/, /[eE]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "oracleCondition$subexpression$1", "symbols": ["oracleCondition$subexpression$1$subexpression$2"]},
    {"name": "oracleCondition$subexpression$1$subexpression$3", "symbols": [/[tT]/, /[eE]/, /[xX]/, /[tT]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "oracleCondition$subexpression$1", "symbols": ["oracleCondition$subexpression$1$subexpression$3"]},
    {"name": "oracleCondition", "symbols": ["oracleCondition$subexpression$1", "nameStringOpValue"], "postprocess": ([, valuePred]) => genericCondition('oracle_text', cardOracleText, valuePred)},
    {"name": "setCondition$subexpression$1$subexpression$1", "symbols": [/[sS]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "setCondition$subexpression$1", "symbols": ["setCondition$subexpression$1$subexpression$1"]},
    {"name": "setCondition$subexpression$1$subexpression$2", "symbols": [/[sS]/, /[eE]/, /[tT]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "setCondition$subexpression$1", "symbols": ["setCondition$subexpression$1$subexpression$2"]},
    {"name": "setCondition$subexpression$1$subexpression$3", "symbols": [/[eE]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "setCondition$subexpression$1", "symbols": ["setCondition$subexpression$1$subexpression$3"]},
    {"name": "setCondition$subexpression$1$subexpression$4", "symbols": [/[eE]/, /[dD]/, /[iI]/, /[tT]/, /[iI]/, /[oO]/, /[nN]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "setCondition$subexpression$1", "symbols": ["setCondition$subexpression$1$subexpression$4"]},
    {"name": "setCondition", "symbols": ["setCondition$subexpression$1", "alphaNumericOpValue"], "postprocess": ([, valuePred]) => genericCondition('set', cardSet, valuePred)},
    {"name": "powerCondition$subexpression$1$subexpression$1", "symbols": [/[pP]/, /[oO]/, /[wW]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "powerCondition$subexpression$1", "symbols": ["powerCondition$subexpression$1$subexpression$1"]},
    {"name": "powerCondition$subexpression$1$subexpression$2", "symbols": [/[pP]/, /[oO]/, /[wW]/, /[eE]/, /[rR]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "powerCondition$subexpression$1", "symbols": ["powerCondition$subexpression$1$subexpression$2"]},
    {"name": "powerCondition", "symbols": ["powerCondition$subexpression$1", "halfIntOpValue"], "postprocess": ([, valuePred]) => genericCondition('power', (c) => parseFloat(cardPower(c)), valuePred)},
    {"name": "toughnessCondition$subexpression$1$subexpression$1", "symbols": [/[tT]/, /[oO]/, /[uU]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "toughnessCondition$subexpression$1", "symbols": ["toughnessCondition$subexpression$1$subexpression$1"]},
    {"name": "toughnessCondition$subexpression$1$subexpression$2", "symbols": [/[tT]/, /[oO]/, /[uU]/, /[gG]/, /[hH]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "toughnessCondition$subexpression$1", "symbols": ["toughnessCondition$subexpression$1$subexpression$2"]},
    {"name": "toughnessCondition$subexpression$1$subexpression$3", "symbols": [/[tT]/, /[oO]/, /[uU]/, /[gG]/, /[hH]/, /[nN]/, /[eE]/, /[sS]/, /[sS]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "toughnessCondition$subexpression$1", "symbols": ["toughnessCondition$subexpression$1$subexpression$3"]},
    {"name": "toughnessCondition", "symbols": ["toughnessCondition$subexpression$1", "halfIntOpValue"], "postprocess": ([, valuePred]) => genericCondition('toughness', (c) => parseFloat(cardToughness(c)), valuePred)},
    {"name": "ptSumCondition$subexpression$1$subexpression$1", "symbols": [/[pP]/, /[tT]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "ptSumCondition$subexpression$1", "symbols": ["ptSumCondition$subexpression$1$subexpression$1"]},
    {"name": "ptSumCondition$subexpression$1$subexpression$2", "symbols": [/[wW]/, /[iI]/, /[lL]/, /[dD]/, /[pP]/, /[aA]/, /[iI]/, /[rR]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "ptSumCondition$subexpression$1", "symbols": ["ptSumCondition$subexpression$1$subexpression$2"]},
    {"name": "ptSumCondition", "symbols": ["ptSumCondition$subexpression$1", "halfIntOpValue"], "postprocess": ([, valuePred]) => genericCondition('pt', (c) => parseFloat(cardToughness(c)) + parseFloat(cardPower(c)), valuePred)},
    {"name": "tagCondition$subexpression$1$subexpression$1", "symbols": [/[tT]/, /[aA]/, /[gG]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "tagCondition$subexpression$1", "symbols": ["tagCondition$subexpression$1$subexpression$1"]},
    {"name": "tagCondition$subexpression$1$subexpression$2", "symbols": [/[tT]/, /[aA]/, /[gG]/, /[sS]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "tagCondition$subexpression$1", "symbols": ["tagCondition$subexpression$1$subexpression$2"]},
    {"name": "tagCondition", "symbols": ["tagCondition$subexpression$1", "stringSetElementOpValue"], "postprocess": ([, valuePred]) => genericCondition('tags', cardTags, valuePred)},
    {"name": "finishCondition$subexpression$1$subexpression$1", "symbols": [/[fF]/, /[iI]/, /[nN]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "finishCondition$subexpression$1", "symbols": ["finishCondition$subexpression$1$subexpression$1"]},
    {"name": "finishCondition$subexpression$1$subexpression$2", "symbols": [/[fF]/, /[iI]/, /[nN]/, /[iI]/, /[sS]/, /[hH]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "finishCondition$subexpression$1", "symbols": ["finishCondition$subexpression$1$subexpression$2"]},
    {"name": "finishCondition", "symbols": ["finishCondition$subexpression$1", "finishOpValue"], "postprocess": ([, valuePred]) => genericCondition('finish', cardFinish, valuePred)},
    {"name": "legalityCondition$subexpression$1$subexpression$1", "symbols": [/[lL]/, /[eE]/, /[gG]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "legalityCondition$subexpression$1", "symbols": ["legalityCondition$subexpression$1$subexpression$1"]},
    {"name": "legalityCondition$subexpression$1$subexpression$2", "symbols": [/[lL]/, /[eE]/, /[gG]/, /[aA]/, /[lL]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "legalityCondition$subexpression$1", "symbols": ["legalityCondition$subexpression$1$subexpression$2"]},
    {"name": "legalityCondition$subexpression$1$subexpression$3", "symbols": [/[lL]/, /[eE]/, /[gG]/, /[aA]/, /[lL]/, /[iI]/, /[tT]/, /[yY]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "legalityCondition$subexpression$1", "symbols": ["legalityCondition$subexpression$1$subexpression$3"]},
    {"name": "legalityCondition", "symbols": ["legalityCondition$subexpression$1", "legalityOpValue"], "postprocess": ([, valuePred]) => genericCondition('legality', cardLegalIn, valuePred)},
    {"name": "bannedCondition$subexpression$1$subexpression$1", "symbols": [/[bB]/, /[aA]/, /[nN]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "bannedCondition$subexpression$1", "symbols": ["bannedCondition$subexpression$1$subexpression$1"]},
    {"name": "bannedCondition$subexpression$1$subexpression$2", "symbols": [/[bB]/, /[aA]/, /[nN]/, /[nN]/, /[eE]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "bannedCondition$subexpression$1", "symbols": ["bannedCondition$subexpression$1$subexpression$2"]},
    {"name": "bannedCondition", "symbols": ["bannedCondition$subexpression$1", "legalityOpValue"], "postprocess": ([, valuePred]) => genericCondition('legality', cardBannedIn, valuePred)},
    {"name": "restrictedCondition$subexpression$1", "symbols": [/[rR]/, /[eE]/, /[sS]/, /[tT]/, /[rR]/, /[iI]/, /[cC]/, /[tT]/, /[eE]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "restrictedCondition", "symbols": ["restrictedCondition$subexpression$1", "legalityOpValue"], "postprocess": ([, valuePred]) => genericCondition('legality', cardRestrictedIn, valuePred)},
    {"name": "priceCondition$subexpression$1$subexpression$1", "symbols": [/[pP]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "priceCondition$subexpression$1", "symbols": ["priceCondition$subexpression$1$subexpression$1"]},
    {"name": "priceCondition$subexpression$1$subexpression$2", "symbols": [/[uU]/, /[sS]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "priceCondition$subexpression$1", "symbols": ["priceCondition$subexpression$1$subexpression$2"]},
    {"name": "priceCondition$subexpression$1$subexpression$3", "symbols": [/[pP]/, /[rR]/, /[iI]/, /[cC]/, /[eE]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "priceCondition$subexpression$1", "symbols": ["priceCondition$subexpression$1$subexpression$3"]},
    {"name": "priceCondition", "symbols": ["priceCondition$subexpression$1", "dollarOpValue"], "postprocess": ([, valuePred]) => genericCondition('price', cardPrice, valuePred)},
    {"name": "normalPriceCondition$subexpression$1$subexpression$1", "symbols": [/[nN]/, /[pP]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "normalPriceCondition$subexpression$1", "symbols": ["normalPriceCondition$subexpression$1$subexpression$1"]},
    {"name": "normalPriceCondition$subexpression$1$subexpression$2", "symbols": [/[pP]/, /[nN]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "normalPriceCondition$subexpression$1", "symbols": ["normalPriceCondition$subexpression$1$subexpression$2"]},
    {"name": "normalPriceCondition$subexpression$1$subexpression$3", "symbols": [/[nN]/, /[oO]/, /[rR]/, /[mM]/, /[aA]/, /[lL]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "normalPriceCondition$subexpression$1", "symbols": ["normalPriceCondition$subexpression$1$subexpression$3"]},
    {"name": "normalPriceCondition$subexpression$1$subexpression$4", "symbols": [/[nN]/, /[oO]/, /[rR]/, /[mM]/, /[aA]/, /[lL]/, /[pP]/, /[rR]/, /[iI]/, /[cC]/, /[eE]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "normalPriceCondition$subexpression$1", "symbols": ["normalPriceCondition$subexpression$1$subexpression$4"]},
    {"name": "normalPriceCondition$subexpression$1$subexpression$5", "symbols": [/[pP]/, /[rR]/, /[iI]/, /[cC]/, /[eE]/, /[nN]/, /[oO]/, /[rR]/, /[mM]/, /[aA]/, /[lL]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "normalPriceCondition$subexpression$1", "symbols": ["normalPriceCondition$subexpression$1$subexpression$5"]},
    {"name": "normalPriceCondition", "symbols": ["normalPriceCondition$subexpression$1", "dollarOpValue"], "postprocess": ([, valuePred]) => genericCondition('price_normal', cardNormalPrice, valuePred)},
    {"name": "foilPriceCondition$subexpression$1$subexpression$1", "symbols": [/[fF]/, /[pP]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "foilPriceCondition$subexpression$1", "symbols": ["foilPriceCondition$subexpression$1$subexpression$1"]},
    {"name": "foilPriceCondition$subexpression$1$subexpression$2", "symbols": [/[uU]/, /[sS]/, /[dD]/, /[fF]/, /[oO]/, /[iI]/, /[lL]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "foilPriceCondition$subexpression$1", "symbols": ["foilPriceCondition$subexpression$1$subexpression$2"]},
    {"name": "foilPriceCondition$subexpression$1$subexpression$3", "symbols": [/[pP]/, /[fF]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "foilPriceCondition$subexpression$1", "symbols": ["foilPriceCondition$subexpression$1$subexpression$3"]},
    {"name": "foilPriceCondition$subexpression$1$subexpression$4", "symbols": [/[fF]/, /[oO]/, /[iI]/, /[lL]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "foilPriceCondition$subexpression$1", "symbols": ["foilPriceCondition$subexpression$1$subexpression$4"]},
    {"name": "foilPriceCondition$subexpression$1$subexpression$5", "symbols": [/[fF]/, /[oO]/, /[iI]/, /[lL]/, /[pP]/, /[rR]/, /[iI]/, /[cC]/, /[eE]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "foilPriceCondition$subexpression$1", "symbols": ["foilPriceCondition$subexpression$1$subexpression$5"]},
    {"name": "foilPriceCondition$subexpression$1$subexpression$6", "symbols": [/[pP]/, /[rR]/, /[iI]/, /[cC]/, /[eE]/, /[fF]/, /[oO]/, /[iI]/, /[lL]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "foilPriceCondition$subexpression$1", "symbols": ["foilPriceCondition$subexpression$1$subexpression$6"]},
    {"name": "foilPriceCondition", "symbols": ["foilPriceCondition$subexpression$1", "dollarOpValue"], "postprocess": ([, valuePred]) => genericCondition('price_foil', cardFoilPrice, valuePred)},
    {"name": "eurPriceCondition$subexpression$1$subexpression$1", "symbols": [/[pP]/, /[eE]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "eurPriceCondition$subexpression$1", "symbols": ["eurPriceCondition$subexpression$1$subexpression$1"]},
    {"name": "eurPriceCondition$subexpression$1$subexpression$2", "symbols": [/[pP]/, /[rR]/, /[iI]/, /[cC]/, /[eE]/, /[eE]/, /[uU]/, /[rR]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "eurPriceCondition$subexpression$1", "symbols": ["eurPriceCondition$subexpression$1$subexpression$2"]},
    {"name": "eurPriceCondition$subexpression$1$subexpression$3", "symbols": [/[eE]/, /[uU]/, /[rR]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "eurPriceCondition$subexpression$1", "symbols": ["eurPriceCondition$subexpression$1$subexpression$3"]},
    {"name": "eurPriceCondition$subexpression$1$subexpression$4", "symbols": [/[eE]/, /[uU]/, /[rR]/, /[pP]/, /[rR]/, /[iI]/, /[cC]/, /[eE]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "eurPriceCondition$subexpression$1", "symbols": ["eurPriceCondition$subexpression$1$subexpression$4"]},
    {"name": "eurPriceCondition", "symbols": ["eurPriceCondition$subexpression$1", "dollarOpValue"], "postprocess": ([, valuePred]) => genericCondition('price_eur', cardPriceEur, valuePred)},
    {"name": "tixPriceCondition$subexpression$1$subexpression$1", "symbols": [/[tT]/, /[iI]/, /[xX]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "tixPriceCondition$subexpression$1", "symbols": ["tixPriceCondition$subexpression$1$subexpression$1"]},
    {"name": "tixPriceCondition$subexpression$1$subexpression$2", "symbols": [/[pP]/, /[rR]/, /[iI]/, /[cC]/, /[eE]/, /[tT]/, /[iI]/, /[xX]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "tixPriceCondition$subexpression$1", "symbols": ["tixPriceCondition$subexpression$1$subexpression$2"]},
    {"name": "tixPriceCondition$subexpression$1$subexpression$3", "symbols": [/[tT]/, /[iI]/, /[xX]/, /[pP]/, /[rR]/, /[iI]/, /[cC]/, /[eE]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "tixPriceCondition$subexpression$1", "symbols": ["tixPriceCondition$subexpression$1$subexpression$3"]},
    {"name": "tixPriceCondition", "symbols": ["tixPriceCondition$subexpression$1", "dollarOpValue"], "postprocess": ([, valuePred]) => genericCondition('price_tix', cardTix, valuePred)},
    {"name": "statusCondition$subexpression$1$subexpression$1", "symbols": [/[sS]/, /[tT]/, /[aA]/, /[tT]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "statusCondition$subexpression$1", "symbols": ["statusCondition$subexpression$1$subexpression$1"]},
    {"name": "statusCondition$subexpression$1$subexpression$2", "symbols": [/[sS]/, /[tT]/, /[aA]/, /[tT]/, /[uU]/, /[sS]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "statusCondition$subexpression$1", "symbols": ["statusCondition$subexpression$1$subexpression$2"]},
    {"name": "statusCondition", "symbols": ["statusCondition$subexpression$1", "statusOpValue"], "postprocess": ([, valuePred]) => genericCondition('status', cardStatus, valuePred)},
    {"name": "rarityCondition$subexpression$1$subexpression$1", "symbols": [/[rR]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "rarityCondition$subexpression$1", "symbols": ["rarityCondition$subexpression$1$subexpression$1"]},
    {"name": "rarityCondition$subexpression$1$subexpression$2", "symbols": [/[rR]/, /[aA]/, /[rR]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "rarityCondition$subexpression$1", "symbols": ["rarityCondition$subexpression$1$subexpression$2"]},
    {"name": "rarityCondition$subexpression$1$subexpression$3", "symbols": [/[rR]/, /[aA]/, /[rR]/, /[iI]/, /[tT]/, /[yY]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "rarityCondition$subexpression$1", "symbols": ["rarityCondition$subexpression$1$subexpression$3"]},
    {"name": "rarityCondition", "symbols": ["rarityCondition$subexpression$1", "rarityOpValue"], "postprocess": ([, valuePred]) => genericCondition('rarity', cardRarity, valuePred)},
    {"name": "loyaltyCondition$subexpression$1$subexpression$1", "symbols": [/[lL]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "loyaltyCondition$subexpression$1", "symbols": ["loyaltyCondition$subexpression$1$subexpression$1"]},
    {"name": "loyaltyCondition$subexpression$1$subexpression$2", "symbols": [/[lL]/, /[oO]/, /[yY]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "loyaltyCondition$subexpression$1", "symbols": ["loyaltyCondition$subexpression$1$subexpression$2"]},
    {"name": "loyaltyCondition$subexpression$1$subexpression$3", "symbols": [/[lL]/, /[oO]/, /[yY]/, /[aA]/, /[lL]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "loyaltyCondition$subexpression$1", "symbols": ["loyaltyCondition$subexpression$1$subexpression$3"]},
    {"name": "loyaltyCondition$subexpression$1$subexpression$4", "symbols": [/[lL]/, /[oO]/, /[yY]/, /[aA]/, /[lL]/, /[tT]/, /[yY]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "loyaltyCondition$subexpression$1", "symbols": ["loyaltyCondition$subexpression$1$subexpression$4"]},
    {"name": "loyaltyCondition", "symbols": ["loyaltyCondition$subexpression$1", "integerOpValue"], "postprocess": ([, valuePred]) => genericCondition('loyalty', cardLoyalty, valuePred)},
    {"name": "artistCondition$subexpression$1$subexpression$1", "symbols": [/[aA]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "artistCondition$subexpression$1", "symbols": ["artistCondition$subexpression$1$subexpression$1"]},
    {"name": "artistCondition$subexpression$1$subexpression$2", "symbols": [/[aA]/, /[rR]/, /[tT]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "artistCondition$subexpression$1", "symbols": ["artistCondition$subexpression$1$subexpression$2"]},
    {"name": "artistCondition$subexpression$1$subexpression$3", "symbols": [/[aA]/, /[rR]/, /[tT]/, /[iI]/, /[sS]/, /[tT]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "artistCondition$subexpression$1", "symbols": ["artistCondition$subexpression$1$subexpression$3"]},
    {"name": "artistCondition", "symbols": ["artistCondition$subexpression$1", "stringOpValue"], "postprocess": ([, valuePred]) => genericCondition('artist', cardArtist, valuePred)},
    {"name": "layoutCondition$subexpression$1", "symbols": [/[lL]/, /[aA]/, /[yY]/, /[oO]/, /[uU]/, /[tT]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "layoutCondition", "symbols": ["layoutCondition$subexpression$1", "stringOpValue"], "postprocess": ([, valuePred]) => genericCondition('layout', cardLayout, valuePred)},
    {"name": "eloCondition$subexpression$1", "symbols": [/[eE]/, /[lL]/, /[oO]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "eloCondition", "symbols": ["eloCondition$subexpression$1", "integerOpValue"], "postprocess": ([, valuePred]) => genericCondition('elo', cardElo, valuePred)},
    {"name": "popularityCondition$subexpression$1$subexpression$1", "symbols": [/[pP]/, /[oO]/, /[pP]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "popularityCondition$subexpression$1", "symbols": ["popularityCondition$subexpression$1$subexpression$1"]},
    {"name": "popularityCondition$subexpression$1$subexpression$2", "symbols": [/[pP]/, /[oO]/, /[pP]/, /[uU]/, /[lL]/, /[aA]/, /[rR]/, /[iI]/, /[tT]/, /[yY]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "popularityCondition$subexpression$1", "symbols": ["popularityCondition$subexpression$1$subexpression$2"]},
    {"name": "popularityCondition", "symbols": ["popularityCondition$subexpression$1", "integerOpValue"], "postprocess": ([, valuePred]) => genericCondition('popularity', cardPopularity, valuePred)},
    {"name": "cubeCountCondition$subexpression$1$subexpression$1", "symbols": [/[cC]/, /[uU]/, /[bB]/, /[eE]/, /[sS]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "cubeCountCondition$subexpression$1", "symbols": ["cubeCountCondition$subexpression$1$subexpression$1"]},
    {"name": "cubeCountCondition$subexpression$1$subexpression$2", "symbols": [/[cC]/, /[uU]/, /[bB]/, /[eE]/, /[cC]/, /[oO]/, /[uU]/, /[nN]/, /[tT]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "cubeCountCondition$subexpression$1", "symbols": ["cubeCountCondition$subexpression$1$subexpression$2"]},
    {"name": "cubeCountCondition$subexpression$1$subexpression$3", "symbols": [/[nN]/, /[uU]/, /[mM]/, /[cC]/, /[uU]/, /[bB]/, /[eE]/, /[sS]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "cubeCountCondition$subexpression$1", "symbols": ["cubeCountCondition$subexpression$1$subexpression$3"]},
    {"name": "cubeCountCondition", "symbols": ["cubeCountCondition$subexpression$1", "integerOpValue"], "postprocess": ([, valuePred]) => genericCondition('cubecount', cardCubeCount, valuePred)},
    {"name": "pickCountCondition$subexpression$1$subexpression$1", "symbols": [/[pP]/, /[iI]/, /[cC]/, /[kK]/, /[sS]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "pickCountCondition$subexpression$1", "symbols": ["pickCountCondition$subexpression$1$subexpression$1"]},
    {"name": "pickCountCondition$subexpression$1$subexpression$2", "symbols": [/[pP]/, /[iI]/, /[cC]/, /[kK]/, /[cC]/, /[oO]/, /[uU]/, /[nN]/, /[tT]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "pickCountCondition$subexpression$1", "symbols": ["pickCountCondition$subexpression$1$subexpression$2"]},
    {"name": "pickCountCondition$subexpression$1$subexpression$3", "symbols": [/[nN]/, /[uU]/, /[mM]/, /[pP]/, /[iI]/, /[cC]/, /[kK]/, /[sS]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "pickCountCondition$subexpression$1", "symbols": ["pickCountCondition$subexpression$1$subexpression$3"]},
    {"name": "pickCountCondition", "symbols": ["pickCountCondition$subexpression$1", "integerOpValue"], "postprocess": ([, valuePred]) => genericCondition('pickcount', cardPickCount, valuePred)},
    {"name": "nameCondition$subexpression$1$subexpression$1", "symbols": [/[nN]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "nameCondition$subexpression$1", "symbols": ["nameCondition$subexpression$1$subexpression$1"]},
    {"name": "nameCondition$subexpression$1$subexpression$2", "symbols": [/[nN]/, /[aA]/, /[mM]/, /[eE]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "nameCondition$subexpression$1", "symbols": ["nameCondition$subexpression$1$subexpression$2"]},
    {"name": "nameCondition", "symbols": ["nameCondition$subexpression$1", "stringOpValue"], "postprocess": ([, valuePred]) => genericCondition('name_lower', cardNameLower, valuePred)},
    {"name": "nameCondition", "symbols": ["stringValue"], "postprocess": ([value]) => genericCondition('name_lower', cardNameLower, (fieldValue) => fieldValue.includes(value.toLowerCase()))},
    {"name": "manaCostCondition$subexpression$1$subexpression$1", "symbols": [/[mM]/, /[aA]/, /[nN]/, /[aA]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "manaCostCondition$subexpression$1", "symbols": ["manaCostCondition$subexpression$1$subexpression$1"]},
    {"name": "manaCostCondition$subexpression$1$subexpression$2", "symbols": [/[cC]/, /[oO]/, /[sS]/, /[tT]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "manaCostCondition$subexpression$1", "symbols": ["manaCostCondition$subexpression$1$subexpression$2"]},
    {"name": "manaCostCondition$subexpression$1$subexpression$3", "symbols": [/[mM]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "manaCostCondition$subexpression$1", "symbols": ["manaCostCondition$subexpression$1$subexpression$3"]},
    {"name": "manaCostCondition", "symbols": ["manaCostCondition$subexpression$1", "manaCostOpValue"], "postprocess": ([, valuePred]) => genericCondition('parsed_cost', cardCost, valuePred)},
    {"name": "castableCostCondition$subexpression$1$subexpression$1", "symbols": [/[cC]/, /[wW]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "castableCostCondition$subexpression$1", "symbols": ["castableCostCondition$subexpression$1$subexpression$1"]},
    {"name": "castableCostCondition$subexpression$1$subexpression$2", "symbols": [/[cC]/, /[aA]/, /[sS]/, /[tT]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "castableCostCondition$subexpression$1", "symbols": ["castableCostCondition$subexpression$1$subexpression$2"]},
    {"name": "castableCostCondition$subexpression$1$subexpression$3", "symbols": [/[cC]/, /[aA]/, /[sS]/, /[tT]/, /[aA]/, /[bB]/, /[lL]/, /[eE]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "castableCostCondition$subexpression$1", "symbols": ["castableCostCondition$subexpression$1$subexpression$3"]},
    {"name": "castableCostCondition$subexpression$1$subexpression$4", "symbols": [/[cC]/, /[aA]/, /[sS]/, /[tT]/, /[wW]/, /[iI]/, /[tT]/, /[hH]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "castableCostCondition$subexpression$1", "symbols": ["castableCostCondition$subexpression$1$subexpression$4"]},
    {"name": "castableCostCondition$subexpression$1$subexpression$5", "symbols": [/[cC]/, /[aA]/, /[sS]/, /[tT]/, /[aA]/, /[bB]/, /[lL]/, /[eE]/, /[wW]/, /[iI]/, /[tT]/, /[hH]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "castableCostCondition$subexpression$1", "symbols": ["castableCostCondition$subexpression$1$subexpression$5"]},
    {"name": "castableCostCondition", "symbols": ["castableCostCondition$subexpression$1", "castableCostOpValue"], "postprocess": ([, valuePred]) => genericCondition('parsed_cost', cardCost, valuePred)},
    {"name": "devotionCondition$subexpression$1$subexpression$1", "symbols": [/[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "devotionCondition$subexpression$1", "symbols": ["devotionCondition$subexpression$1$subexpression$1"]},
    {"name": "devotionCondition$subexpression$1$subexpression$2", "symbols": [/[dD]/, /[eE]/, /[vV]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "devotionCondition$subexpression$1", "symbols": ["devotionCondition$subexpression$1$subexpression$2"]},
    {"name": "devotionCondition$subexpression$1$subexpression$3", "symbols": [/[dD]/, /[eE]/, /[vV]/, /[oO]/, /[tT]/, /[iI]/, /[oO]/, /[nN]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "devotionCondition$subexpression$1", "symbols": ["devotionCondition$subexpression$1$subexpression$3"]},
    {"name": "devotionCondition$subexpression$1$subexpression$4", "symbols": [/[dD]/, /[eE]/, /[vV]/, /[oO]/, /[tT]/, /[iI]/, /[oO]/, /[nN]/, /[tT]/, /[oO]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "devotionCondition$subexpression$1", "symbols": ["devotionCondition$subexpression$1$subexpression$4"]},
    {"name": "devotionCondition$subexpression$2$subexpression$1", "symbols": [/[wW]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "devotionCondition$subexpression$2", "symbols": ["devotionCondition$subexpression$2$subexpression$1"]},
    {"name": "devotionCondition$subexpression$2$subexpression$2", "symbols": [/[uU]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "devotionCondition$subexpression$2", "symbols": ["devotionCondition$subexpression$2$subexpression$2"]},
    {"name": "devotionCondition$subexpression$2$subexpression$3", "symbols": [/[bB]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "devotionCondition$subexpression$2", "symbols": ["devotionCondition$subexpression$2$subexpression$3"]},
    {"name": "devotionCondition$subexpression$2$subexpression$4", "symbols": [/[rR]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "devotionCondition$subexpression$2", "symbols": ["devotionCondition$subexpression$2$subexpression$4"]},
    {"name": "devotionCondition$subexpression$2$subexpression$5", "symbols": [/[gG]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "devotionCondition$subexpression$2", "symbols": ["devotionCondition$subexpression$2$subexpression$5"]},
    {"name": "devotionCondition", "symbols": ["devotionCondition$subexpression$1", "devotionCondition$subexpression$2", "anyOperator", "integerValue"], "postprocess": ([, [color], op, value]) => genericCondition('parsed_cost', (c) => c, devotionOperation(op, color, value))},
    {"name": "devotionCondition$subexpression$3$subexpression$1", "symbols": [/[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "devotionCondition$subexpression$3", "symbols": ["devotionCondition$subexpression$3$subexpression$1"]},
    {"name": "devotionCondition$subexpression$3$subexpression$2", "symbols": [/[dD]/, /[eE]/, /[vV]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "devotionCondition$subexpression$3", "symbols": ["devotionCondition$subexpression$3$subexpression$2"]},
    {"name": "devotionCondition$subexpression$3$subexpression$3", "symbols": [/[dD]/, /[eE]/, /[vV]/, /[oO]/, /[tT]/, /[iI]/, /[oO]/, /[nN]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "devotionCondition$subexpression$3", "symbols": ["devotionCondition$subexpression$3$subexpression$3"]},
    {"name": "devotionCondition$subexpression$3$subexpression$4", "symbols": [/[dD]/, /[eE]/, /[vV]/, /[oO]/, /[tT]/, /[iI]/, /[oO]/, /[nN]/, /[tT]/, /[oO]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "devotionCondition$subexpression$3", "symbols": ["devotionCondition$subexpression$3$subexpression$4"]},
    {"name": "devotionCondition", "symbols": ["devotionCondition$subexpression$3", "devotionOpValue"], "postprocess": ([, valuePred]) => genericCondition('parsed_cost', (c) => c, valuePred)},
    {"name": "collectorNumberCondition$subexpression$1$subexpression$1", "symbols": [/[cC]/, /[nN]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "collectorNumberCondition$subexpression$1", "symbols": ["collectorNumberCondition$subexpression$1$subexpression$1"]},
    {"name": "collectorNumberCondition$subexpression$1$subexpression$2", "symbols": [/[nN]/, /[uU]/, /[mM]/, /[bB]/, /[eE]/, /[rR]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "collectorNumberCondition$subexpression$1", "symbols": ["collectorNumberCondition$subexpression$1$subexpression$2"]},
    {"name": "collectorNumberCondition", "symbols": ["collectorNumberCondition$subexpression$1", "stringExactOpValue"], "postprocess": ([, valuePred]) => genericCondition('collector_number', cardCollectorNumber, valuePred)},
    {"name": "isCondition$subexpression$1", "symbols": [/[iI]/, /[sS]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "isCondition", "symbols": ["isCondition$subexpression$1", "isOpValue"], "postprocess": ([, valuePred]) => genericCondition('details', ({ details }) => details, valuePred)},
    {"name": "notCondition$subexpression$1", "symbols": [/[nN]/, /[oO]/, /[tT]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "notCondition", "symbols": ["notCondition$subexpression$1", "isOpValue"], "postprocess": ([, valuePred]) => negated(genericCondition('details', ({ details }) => details, valuePred))},
    {"name": "isOpValue", "symbols": [{"literal":":"}, "isValue"], "postprocess": ([, category]) => CARD_CATEGORY_DETECTORS[category]},
    {"name": "isValue$subexpression$1$subexpression$1", "symbols": [/[gG]/, /[oO]/, /[lL]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "isValue$subexpression$1", "symbols": ["isValue$subexpression$1$subexpression$1"]},
    {"name": "isValue$subexpression$1$subexpression$2", "symbols": [/[tT]/, /[wW]/, /[oO]/, /[bB]/, /[rR]/, /[iI]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "isValue$subexpression$1", "symbols": ["isValue$subexpression$1$subexpression$2"]},
    {"name": "isValue$subexpression$1$subexpression$3", "symbols": [/[hH]/, /[yY]/, /[bB]/, /[rR]/, /[iI]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "isValue$subexpression$1", "symbols": ["isValue$subexpression$1$subexpression$3"]},
    {"name": "isValue$subexpression$1$subexpression$4", "symbols": [/[pP]/, /[hH]/, /[yY]/, /[rR]/, /[eE]/, /[xX]/, /[iI]/, /[aA]/, /[nN]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "isValue$subexpression$1", "symbols": ["isValue$subexpression$1$subexpression$4"]},
    {"name": "isValue$subexpression$1$subexpression$5", "symbols": [/[pP]/, /[rR]/, /[oO]/, /[mM]/, /[oO]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "isValue$subexpression$1", "symbols": ["isValue$subexpression$1$subexpression$5"]},
    {"name": "isValue$subexpression$1$subexpression$6", "symbols": [/[rR]/, /[eE]/, /[pP]/, /[rR]/, /[iI]/, /[nN]/, /[tT]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "isValue$subexpression$1", "symbols": ["isValue$subexpression$1$subexpression$6"]},
    {"name": "isValue$subexpression$1$subexpression$7", "symbols": [/[fF]/, /[iI]/, /[rR]/, /[sS]/, /[tT]/, /[pP]/, /[rR]/, /[iI]/, /[nN]/, /[tT]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "isValue$subexpression$1", "symbols": ["isValue$subexpression$1$subexpression$7"]},
    {"name": "isValue$subexpression$1$subexpression$8", "symbols": [/[fF]/, /[iI]/, /[rR]/, /[sS]/, /[tT]/, /[pP]/, /[rR]/, /[iI]/, /[nN]/, /[tT]/, /[iI]/, /[nN]/, /[gG]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "isValue$subexpression$1", "symbols": ["isValue$subexpression$1$subexpression$8"]},
    {"name": "isValue$subexpression$1$subexpression$9", "symbols": [/[dD]/, /[iI]/, /[gG]/, /[iI]/, /[tT]/, /[aA]/, /[lL]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "isValue$subexpression$1", "symbols": ["isValue$subexpression$1$subexpression$9"]},
    {"name": "isValue$subexpression$1$subexpression$10", "symbols": [/[rR]/, /[eE]/, /[aA]/, /[sS]/, /[oO]/, /[nN]/, /[aA]/, /[bB]/, /[lL]/, /[eE]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "isValue$subexpression$1", "symbols": ["isValue$subexpression$1$subexpression$10"]},
    {"name": "isValue$subexpression$1$subexpression$11", "symbols": [/[dD]/, /[fF]/, /[cC]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "isValue$subexpression$1", "symbols": ["isValue$subexpression$1$subexpression$11"]},
    {"name": "isValue$subexpression$1$subexpression$12", "symbols": [/[mM]/, /[dD]/, /[fF]/, /[cC]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "isValue$subexpression$1", "symbols": ["isValue$subexpression$1$subexpression$12"]},
    {"name": "isValue$subexpression$1$subexpression$13", "symbols": [/[tT]/, /[dD]/, /[fF]/, /[cC]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "isValue$subexpression$1", "symbols": ["isValue$subexpression$1$subexpression$13"]},
    {"name": "isValue$subexpression$1$subexpression$14", "symbols": [/[mM]/, /[eE]/, /[lL]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "isValue$subexpression$1", "symbols": ["isValue$subexpression$1$subexpression$14"]},
    {"name": "isValue$subexpression$1$subexpression$15", "symbols": [/[tT]/, /[rR]/, /[aA]/, /[nN]/, /[sS]/, /[fF]/, /[oO]/, /[rR]/, /[mM]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "isValue$subexpression$1", "symbols": ["isValue$subexpression$1$subexpression$15"]},
    {"name": "isValue$subexpression$1$subexpression$16", "symbols": [/[sS]/, /[pP]/, /[lL]/, /[iI]/, /[tT]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "isValue$subexpression$1", "symbols": ["isValue$subexpression$1$subexpression$16"]},
    {"name": "isValue$subexpression$1$subexpression$17", "symbols": [/[fF]/, /[lL]/, /[iI]/, /[pP]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "isValue$subexpression$1", "symbols": ["isValue$subexpression$1$subexpression$17"]},
    {"name": "isValue$subexpression$1$subexpression$18", "symbols": [/[lL]/, /[eE]/, /[vV]/, /[eE]/, /[lL]/, /[eE]/, /[rR]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "isValue$subexpression$1", "symbols": ["isValue$subexpression$1$subexpression$18"]},
    {"name": "isValue$subexpression$1$subexpression$19", "symbols": [/[cC]/, /[oO]/, /[mM]/, /[mM]/, /[aA]/, /[nN]/, /[dD]/, /[eE]/, /[rR]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "isValue$subexpression$1", "symbols": ["isValue$subexpression$1$subexpression$19"]},
    {"name": "isValue$subexpression$1$subexpression$20", "symbols": [/[sS]/, /[pP]/, /[eE]/, /[lL]/, /[lL]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "isValue$subexpression$1", "symbols": ["isValue$subexpression$1$subexpression$20"]},
    {"name": "isValue$subexpression$1$subexpression$21", "symbols": [/[pP]/, /[eE]/, /[rR]/, /[mM]/, /[aA]/, /[nN]/, /[eE]/, /[nN]/, /[tT]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "isValue$subexpression$1", "symbols": ["isValue$subexpression$1$subexpression$21"]},
    {"name": "isValue$subexpression$1$subexpression$22", "symbols": [/[hH]/, /[iI]/, /[sS]/, /[tT]/, /[oO]/, /[rR]/, /[iI]/, /[cC]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "isValue$subexpression$1", "symbols": ["isValue$subexpression$1$subexpression$22"]},
    {"name": "isValue$subexpression$1$subexpression$23", "symbols": [/[vV]/, /[aA]/, /[nN]/, /[iI]/, /[lL]/, /[lL]/, /[aA]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "isValue$subexpression$1", "symbols": ["isValue$subexpression$1$subexpression$23"]},
    {"name": "isValue$subexpression$1$subexpression$24", "symbols": [/[mM]/, /[oO]/, /[dD]/, /[aA]/, /[lL]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "isValue$subexpression$1", "symbols": ["isValue$subexpression$1$subexpression$24"]},
    {"name": "isValue$subexpression$1$subexpression$25", "symbols": [/[fF]/, /[uU]/, /[lL]/, /[lL]/, /[aA]/, /[rR]/, /[tT]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "isValue$subexpression$1", "symbols": ["isValue$subexpression$1$subexpression$25"]},
    {"name": "isValue$subexpression$1$subexpression$26", "symbols": [/[fF]/, /[oO]/, /[iI]/, /[lL]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "isValue$subexpression$1", "symbols": ["isValue$subexpression$1$subexpression$26"]},
    {"name": "isValue$subexpression$1$subexpression$27", "symbols": [/[nN]/, /[oO]/, /[nN]/, /[fF]/, /[oO]/, /[iI]/, /[lL]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "isValue$subexpression$1", "symbols": ["isValue$subexpression$1$subexpression$27"]},
    {"name": "isValue$subexpression$1$subexpression$28", "symbols": [/[eE]/, /[tT]/, /[cC]/, /[hH]/, /[eE]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "isValue$subexpression$1", "symbols": ["isValue$subexpression$1$subexpression$28"]},
    {"name": "isValue$subexpression$1$subexpression$29", "symbols": [/[bB]/, /[iI]/, /[kK]/, /[eE]/, /[lL]/, /[aA]/, /[nN]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "isValue$subexpression$1", "symbols": ["isValue$subexpression$1$subexpression$29"]},
    {"name": "isValue$subexpression$1$subexpression$30", "symbols": [/[cC]/, /[yY]/, /[cC]/, /[lL]/, /[eE]/, /[lL]/, /[aA]/, /[nN]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "isValue$subexpression$1", "symbols": ["isValue$subexpression$1$subexpression$30"]},
    {"name": "isValue$subexpression$1$subexpression$31", "symbols": [/[bB]/, /[iI]/, /[cC]/, /[yY]/, /[cC]/, /[lL]/, /[eE]/, /[lL]/, /[aA]/, /[nN]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "isValue$subexpression$1", "symbols": ["isValue$subexpression$1$subexpression$31"]},
    {"name": "isValue$subexpression$1$subexpression$32", "symbols": [/[bB]/, /[oO]/, /[uU]/, /[nN]/, /[cC]/, /[eE]/, /[lL]/, /[aA]/, /[nN]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "isValue$subexpression$1", "symbols": ["isValue$subexpression$1$subexpression$32"]},
    {"name": "isValue$subexpression$1$subexpression$33", "symbols": [/[kK]/, /[aA]/, /[rR]/, /[oO]/, /[oO]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "isValue$subexpression$1", "symbols": ["isValue$subexpression$1$subexpression$33"]},
    {"name": "isValue$subexpression$1$subexpression$34", "symbols": [/[cC]/, /[aA]/, /[nN]/, /[oO]/, /[pP]/, /[yY]/, /[lL]/, /[aA]/, /[nN]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "isValue$subexpression$1", "symbols": ["isValue$subexpression$1$subexpression$34"]},
    {"name": "isValue$subexpression$1$subexpression$35", "symbols": [/[cC]/, /[aA]/, /[nN]/, /[lL]/, /[aA]/, /[nN]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "isValue$subexpression$1", "symbols": ["isValue$subexpression$1$subexpression$35"]},
    {"name": "isValue$subexpression$1$subexpression$36", "symbols": [/[fF]/, /[eE]/, /[tT]/, /[cC]/, /[hH]/, /[lL]/, /[aA]/, /[nN]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "isValue$subexpression$1", "symbols": ["isValue$subexpression$1$subexpression$36"]},
    {"name": "isValue$subexpression$1$subexpression$37", "symbols": [/[cC]/, /[hH]/, /[eE]/, /[cC]/, /[kK]/, /[lL]/, /[aA]/, /[nN]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "isValue$subexpression$1", "symbols": ["isValue$subexpression$1$subexpression$37"]},
    {"name": "isValue$subexpression$1$subexpression$38", "symbols": [/[dD]/, /[uU]/, /[aA]/, /[lL]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "isValue$subexpression$1", "symbols": ["isValue$subexpression$1$subexpression$38"]},
    {"name": "isValue$subexpression$1$subexpression$39", "symbols": [/[fF]/, /[aA]/, /[sS]/, /[tT]/, /[lL]/, /[aA]/, /[nN]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "isValue$subexpression$1", "symbols": ["isValue$subexpression$1$subexpression$39"]},
    {"name": "isValue$subexpression$1$subexpression$40", "symbols": [/[fF]/, /[iI]/, /[lL]/, /[tT]/, /[eE]/, /[rR]/, /[lL]/, /[aA]/, /[nN]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "isValue$subexpression$1", "symbols": ["isValue$subexpression$1$subexpression$40"]},
    {"name": "isValue$subexpression$1$subexpression$41", "symbols": [/[gG]/, /[aA]/, /[iI]/, /[nN]/, /[lL]/, /[aA]/, /[nN]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "isValue$subexpression$1", "symbols": ["isValue$subexpression$1$subexpression$41"]},
    {"name": "isValue$subexpression$1$subexpression$42", "symbols": [/[pP]/, /[aA]/, /[iI]/, /[nN]/, /[lL]/, /[aA]/, /[nN]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "isValue$subexpression$1", "symbols": ["isValue$subexpression$1$subexpression$42"]},
    {"name": "isValue$subexpression$1$subexpression$43", "symbols": [/[sS]/, /[cC]/, /[rR]/, /[yY]/, /[lL]/, /[aA]/, /[nN]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "isValue$subexpression$1", "symbols": ["isValue$subexpression$1$subexpression$43"]},
    {"name": "isValue$subexpression$1$subexpression$44", "symbols": [/[sS]/, /[hH]/, /[aA]/, /[dD]/, /[oO]/, /[wW]/, /[lL]/, /[aA]/, /[nN]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "isValue$subexpression$1", "symbols": ["isValue$subexpression$1$subexpression$44"]},
    {"name": "isValue$subexpression$1$subexpression$45", "symbols": [/[sS]/, /[hH]/, /[oO]/, /[cC]/, /[kK]/, /[lL]/, /[aA]/, /[nN]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "isValue$subexpression$1", "symbols": ["isValue$subexpression$1$subexpression$45"]},
    {"name": "isValue$subexpression$1$subexpression$46", "symbols": [/[sS]/, /[tT]/, /[oO]/, /[rR]/, /[aA]/, /[gG]/, /[eE]/, /[lL]/, /[aA]/, /[nN]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "isValue$subexpression$1", "symbols": ["isValue$subexpression$1$subexpression$46"]},
    {"name": "isValue$subexpression$1$subexpression$47", "symbols": [/[cC]/, /[rR]/, /[eE]/, /[aA]/, /[tT]/, /[uU]/, /[rR]/, /[eE]/, /[lL]/, /[aA]/, /[nN]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "isValue$subexpression$1", "symbols": ["isValue$subexpression$1$subexpression$47"]},
    {"name": "isValue$subexpression$1$subexpression$48", "symbols": [/[mM]/, /[aA]/, /[nN]/, /[lL]/, /[aA]/, /[nN]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "isValue$subexpression$1", "symbols": ["isValue$subexpression$1$subexpression$48"]},
    {"name": "isValue$subexpression$1$subexpression$49", "symbols": [/[tT]/, /[rR]/, /[iI]/, /[lL]/, /[aA]/, /[nN]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "isValue$subexpression$1", "symbols": ["isValue$subexpression$1$subexpression$49"]},
    {"name": "isValue$subexpression$1$subexpression$50", "symbols": [/[tT]/, /[aA]/, /[nN]/, /[gG]/, /[oO]/, /[lL]/, /[aA]/, /[nN]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "isValue$subexpression$1", "symbols": ["isValue$subexpression$1$subexpression$50"]},
    {"name": "isValue$subexpression$1$subexpression$51", "symbols": [/[bB]/, /[aA]/, /[tT]/, /[tT]/, /[lL]/, /[eE]/, /[lL]/, /[aA]/, /[nN]/, /[dD]/], "postprocess": function(d) {return d.join(""); }},
    {"name": "isValue$subexpression$1", "symbols": ["isValue$subexpression$1$subexpression$51"]},
    {"name": "isValue", "symbols": ["isValue$subexpression$1"], "postprocess": ([[category]]) => category.toLowerCase()}
]
  , ParserStart: "start"
}
; export default grammar;
